const submissionsApi = '/submissions';

export const getSubmissionDetailsAPIPath = (id: string) =>
  `${submissionsApi}/${id}`;
export const createSubmissionAPIPath = () => submissionsApi;
export const updateSubmissionAPIPath = (id: string) =>
  `${submissionsApi}/${id}`;
export const uploadSubmissionResumeAPIPath = (id: string) =>
  `${submissionsApi}/${id}/resume`;

export const setSubmissionScheduledSlotAPIPath = (id: string) =>
  `${submissionsApi}/${id}/interview/schedule`;

export const markSubmissionAsRejectedAPIPath = (id: string) =>
  `${submissionsApi}/${id}/reject`;
