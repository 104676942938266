import { ApiStatus, AuthStatus } from '@constants';

export const isStatusLoading = (status: ApiStatus) =>
  status === ApiStatus.LOADING;
export const isAuthStatusLoading = (status: AuthStatus) =>
  status === AuthStatus.LOADING;
export const isStatusComplete = (status: ApiStatus) =>
  status === ApiStatus.COMPLETE;

export const isStatusIdle = (status: ApiStatus) => status === ApiStatus.IDLE;
export const isStatusFailed = (status: ApiStatus) =>
  status === ApiStatus.FAILED;
export const isStatusForbidden = (status: ApiStatus) =>
  status === ApiStatus.FORBIDDEN;
