import { CandidateField, Flex, ModalSectionLayout } from '@components';

import { CreateCandidateFormik } from '@types';

export const ChannelDetailsSection: React.FC<{
  formik: CreateCandidateFormik;
}> = ({ formik }) => {
  return (
    <ModalSectionLayout title="Channel">
      <Flex flexDirection="column" gap={1}>
        <CandidateField
          field="Type_of_channel"
          value={formik.values.Type_of_channel}
          disabled
          touched={!!formik.touched.Type_of_channel || !!formik.submitCount}
          error={formik.errors.Type_of_channel}
          onChange={(value) => formik.setFieldValue('Type_of_channel', value)}
          onBlur={() => formik.setFieldTouched('Type_of_channel')}
        />
        <CandidateField
          field="Type_of_Outbound_channel"
          value={formik.values.Type_of_Outbound_channel}
          disabled
          touched={
            !!formik.touched.Type_of_Outbound_channel || !!formik.submitCount
          }
          error={formik.errors.Type_of_Outbound_channel}
          onChange={(value) =>
            formik.setFieldValue('Type_of_Outbound_channel', value)
          }
          onBlur={() => formik.setFieldTouched('Type_of_Outbound_channel')}
        />
        <CandidateField
          field="Self_gen_Touch_Date"
          value={formik.values.Self_gen_Touch_Date}
          required
          touched={!!formik.touched.Self_gen_Touch_Date || !!formik.submitCount}
          error={formik.errors.Self_gen_Touch_Date}
          onChange={(value) =>
            formik.setFieldValue('Self_gen_Touch_Date', value)
          }
          onBlur={() => formik.setFieldTouched('Self_gen_Touch_Date')}
        />
      </Flex>
    </ModalSectionLayout>
  );
};
