import { Button, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Flex } from '@components';

export const CrucialRequirementsEmptyState: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <Flex
      sx={(theme) => ({
        background: theme.palette.background.backgroundPrimary,
        borderRadius: '8px',
      })}
      flexDirection="column"
      gap={1}
      py={8}
      px={4}
    >
      <Button
        variant="contained"
        color="secondary"
        size="small"
        startIcon={<AddCircleOutlineIcon />}
        onClick={onClick}
      >
        Add crucial requirement
      </Button>
      <Typography textAlign="center">
        Candidates will be asked additional questions to confirm if they have
        experience with required technology, domain, etc.
      </Typography>
    </Flex>
  );
};
