import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useTitle } from '@hooks/useTitle';

import {
  JobOpeningName,
  EntityDetailsLayout,
  JobOpeningDetails,
  JobOpeningNavbarLinks,
} from '@components';

import {
  jobOpeningDetailsSelectors,
  setJobOpeningId,
} from '@redux/jobOpeningDetails';

import { isStatusLoading } from '@utils';
import { AppRoutes } from '@constants';

export const JobOpeningDetailsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { jobOpeningId } = useParams();

  const setPageTitle = useTitle();

  const jobOpeningDetails = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningDetailsData,
  );

  useEffect(() => {
    if (jobOpeningDetails?.Job_Opening_ID) {
      setPageTitle(
        `#${jobOpeningDetails?.Job_Opening_ID} ${jobOpeningDetails?.Client_Name_New}`,
      );
    }
  }, [setPageTitle, jobOpeningDetails?.Job_Opening_ID]);

  useEffect(() => {
    if (jobOpeningId) {
      dispatch(setJobOpeningId(jobOpeningId));
    }
  }, [jobOpeningId]);

  const jobOpeningApiStatus = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningDetailsApiStatus,
  );

  // Header
  const handleBackButtonClick = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else {
      navigate(`/${AppRoutes.JOB_OPENINGS}`);
    }
  };

  return (
    <EntityDetailsLayout
      headerOptions={{
        HeaderComponent: <JobOpeningName {...jobOpeningDetails} />,
        onBackButtonClick: handleBackButtonClick,
        showHeaderContentSmDown: true,
        rightSideOptions: <JobOpeningNavbarLinks />,
      }}
      loading={isStatusLoading(jobOpeningApiStatus)}
    >
      <JobOpeningDetails />
    </EntityDetailsLayout>
  );
};
