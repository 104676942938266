import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useIsMobileView } from '@hooks/useIsMobileView';

import { Hidden, Stack, Typography } from '@mui/material';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';

import { ForbiddenView, LoaderSkeleton, CustomButton } from '@components';
import { SubmissionItem } from './components/SubmissionItem';

import { useAppDispatch, useAppSelector } from '@redux/hooks';
import {
  candidateDetailsSelectors,
  fetchCandidateSubmissions,
} from '@redux/candidateDetails';

import { isStatusForbidden, isStatusIdle, isStatusLoading } from '@utils';
import { AppRoutes, SearchParams } from '@constants';

export const CandidateSubmissions: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMobileView = useIsMobileView();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const candidateDetails = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );
  const { submissionsAPIStatus, submissions: candidateSubmissions } =
    useAppSelector(candidateDetailsSelectors.getCandidateSubmissions);

  const [selectedSubmissionId, setSelectedSubmissionId] = useState<
    string | null
  >(null);

  const submissionId = useMemo(() => {
    return searchParams.get(SearchParams.SUBMISSION_ID);
  }, [searchParams]);

  useEffect(() => {
    if (isStatusIdle(submissionsAPIStatus) && candidateDetails?.id) {
      dispatch(fetchCandidateSubmissions(candidateDetails.id));
    }
  }, [submissionsAPIStatus, candidateDetails?.id]);

  useEffect(() => {
    if (!isMobileView) {
      setSelectedSubmissionId(
        submissionId || candidateSubmissions[0]?.id || null,
      );
    }
  }, [candidateSubmissions, isMobileView, submissionId]);

  const handleSubmissionChange = useCallback((newValue: string) => {
    setSelectedSubmissionId(newValue);
  }, []);

  const handleSubmissionButtonClick = useCallback(() => {
    navigate(
      `/${AppRoutes.SUBMISSIONS}?${SearchParams.SELECTED_CANDIDATE}=${candidateDetails?.id}`,
    );
  }, []);

  if (!candidateDetails) return null;

  if (isStatusLoading(submissionsAPIStatus)) {
    return <LoaderSkeleton />;
  }

  if (isStatusForbidden(submissionsAPIStatus)) {
    return <ForbiddenView />;
  }

  const SubmissionButton = () => (
    <CustomButton
      size="small"
      color="secondary"
      label={'Submit to client'}
      startIcon={<MailOutlineRoundedIcon fontSize="small" />}
      onClick={handleSubmissionButtonClick}
    />
  );

  if (!candidateSubmissions.length)
    return (
      <Stack
        gap={'1rem'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography variant="h3" textAlign="center">
          No submissions
        </Typography>
        <Stack direction={'row'} justifyContent={'center'}>
          <SubmissionButton />
        </Stack>
      </Stack>
    );

  return (
    <React.Fragment>
      <Stack my={'0.25rem'} gap={'1rem'}>
        <Stack
          gap={'0.5rem'}
          flexDirection={'row'}
          sx={{
            mx: {
              xs: '1rem',
              sm: '0.25rem',
            },
          }}
        >
          <Typography variant="h3" flex={1} my={'auto'}>
            {candidateSubmissions.length} submission(s)
          </Typography>
          <Hidden smDown>
            <SubmissionButton />
          </Hidden>
        </Stack>
        <Stack
          sx={{
            backgroundColor: {
              xs: 'none',
              sm: 'background.backgroundPrimary',
            },
            borderRadius: '0.5rem',
            padding: {
              xs: '0',
              sm: '1rem',
            },
            mx: {
              xs: '0',
              sm: '-1rem',
            },
          }}
        >
          {candidateSubmissions.map((submission) => (
            <SubmissionItem
              key={submission.id}
              submission={submission}
              selectedSubmissionId={selectedSubmissionId}
              handleSubmissionChange={handleSubmissionChange}
            />
          ))}
        </Stack>
        <Hidden smUp>
          <Stack
            direction={'row'}
            sx={{
              mx: {
                xs: '1rem',
                sm: 0,
              },
            }}
          >
            <SubmissionButton />
          </Stack>
        </Hidden>
      </Stack>
    </React.Fragment>
  );
};
