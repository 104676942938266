import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

import InterVariableFont from './../assets/fonts/Inter.woff2';

export default <Components<Theme>>{
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'Inter var';
        font-style: normal;
        font-display: swap;
        font-weight: 100 900;
        src: url(${InterVariableFont}) format('woff2');
        font-named-instance: 'Regular',
      }
      html {
        -webkit-font-smoothing: auto;
      }
    `,
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow:
          '0px 3px 3px -2px rgba(0, 0, 0, 0.20), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        ...(ownerState.variant === 'outlined' &&
          ownerState.color === 'primary' && {
            color: theme.palette.button.outlined.primary.text,
            borderColor: theme.palette.button.outlined.primary.border,
            background: theme.palette.button.outlined.primary.background,
            textTransform: 'none',
            '&:hover': {
              color: theme.palette.button.outlined.primary.textHover,
              borderColor: theme.palette.button.outlined.primary.borderHover,
              background: theme.palette.button.outlined.primary.backgroundHover,
            },
          }),
        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'primary' && {
            color: theme.palette.button.contained.primary.text,
            borderColor: theme.palette.button.contained.primary.border,
            background: theme.palette.button.contained.primary.background,
            textTransform: 'none',
            '&:hover': {
              color: theme.palette.button.contained.primary.textHover,
              borderColor: theme.palette.button.contained.primary.borderHover,
              background:
                theme.palette.button.contained.primary.backgroundHover,
            },
          }),
        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'secondary' && {
            color: theme.palette.button.contained.secondary.text,
            borderColor: theme.palette.button.contained.secondary.border,
            background: theme.palette.button.contained.secondary.background,
            textTransform: 'none',
            '&:hover': {
              color: theme.palette.button.contained.secondary.textHover,
              borderColor: theme.palette.button.contained.secondary.borderHover,
              background:
                theme.palette.button.contained.secondary.backgroundHover,
            },
          }),
      }),
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: '0px 10px',
        '&.Mui-checked': {
          color: theme.palette.background.link,
        },
      }),
    },
  },
  MuiDrawer: {
    styleOverrides: {
      root: {
        '&.MuiDrawer-root .MuiBackdrop-root': {
          background: 'transparent',
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-checked': {
          color: theme.palette.text.link,
        },
        '&:hover': {
          backgroundColor: theme.palette.text.linkLight,
        },
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-disabled': {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.inputDisabled,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.text.link,
          },
        },
      }),
      input: ({ theme }) => ({
        '&.Mui-disabled': {
          textFillColor: theme.palette.text.primary,
        },
      }),
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-focused.MuiInputLabel-outlined': {
          color: theme.palette.text.link,
        },
      }),
    },
  },
  MuiChip: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.MuiChip-colorPrimary': {
          backgroundColor: theme.palette.highlight.accent,
          borderRadius: theme.shape.borderRadius,
          color: theme.palette.primary.main,
        },
        '&.MuiChip-colorSecondary': {
          backgroundColor: theme.palette.highlight.sectionLight,
          borderRadius: theme.shape.borderRadius,
          color: theme.palette.primary.main,
          padding: '0.375rem 0',
        },
      }),
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      endAdornment: {
        top: 'calc(50% - 0.875rem)',
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: () => ({
        listStyleType: '"•"',
        '& .MuiListItem-root': {
          display: 'list-item',
          padding: 0,
          paddingLeft: '8px',
          lineHeight: '140%',
          marginBottom: '12px',
        },
      }),
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: () => ({
        fontSize: '0.875rem',
        borderBottomColor: 'rgb(0 0 0 / 8%)',
      }),
      head: ({ theme }) => ({
        fontWeight: 400,
        color: theme.palette.text.secondary,
        lineHeight: '1.1',
        fontSize: '0.8rem',
      }),
    },
  },

  MuiTableSortLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.secondary,
        lineHeight: '1.1',
        fontSize: '0.8rem',
      }),
    },
  },
  MuiTimePickerToolbar: {
    styleOverrides: {
      hourMinuteLabel: { alignItems: 'flex-start' },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: 'rgb(0 0 0 / 8%)',
      },
    },
  },
  MuiSlider: {
    styleOverrides: {
      root: ({ theme }) => ({
        marginBottom: '1rem',
        '.MuiSlider-rail': {
          height: '2px',
        },
        '.MuiSlider-track': {
          backgroundColor: '#3F8CFF',
          height: '2px',
          border: 'none',
        },
        '.MuiSlider-thumb': {
          height: '12px',
          width: '12px',
          backgroundColor: '#3F8CFF',
          '.MuiSlider-valueLabel': {
            background: 'none',
            color: theme.palette.text.secondary,
            top: '45px',
            fontWeight: 400,
          },
        },
      }),
    },
  },
};
