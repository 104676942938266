import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SnackbarType, apiEndpoints } from '@constants';
import { submissionStoreKey } from './submission.const';
import {
  CandidateTestTask,
  CandidateVideoInterview,
  DateTimeRange,
  ICandidateSubmission,
  IZohoCandidate,
  SubmissionFormPayload,
} from '@types';
import { setSnackbar } from '@redux/snackbar';

export const fetchSubmissionDetails = createAsyncThunk(
  `${submissionStoreKey}/fetchSubmissionDetails`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<ICandidateSubmission>(
        apiEndpoints.getSubmissionDetailsAPIPath(id),
      );

      return response.data || null;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchSubmissionCandidateDetails = createAsyncThunk(
  `${submissionStoreKey}/fetchSubmissionCandidateDetails`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<IZohoCandidate>(
        apiEndpoints.candidateById(id),
      );

      return response.data || null;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchSubmissionVideoInterview = createAsyncThunk(
  `${submissionStoreKey}/fetchSubmissionVideoInterview`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<CandidateVideoInterview[]>(
        apiEndpoints.candidateVideoInterview(id),
      );

      return response.data[0] || null;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchSubmissionTestTasks = createAsyncThunk(
  `${submissionStoreKey}/fetchSubmissionTestTasks`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<CandidateTestTask[]>(
        apiEndpoints.getUserTestTasksAPIPath(id),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const createSubmission = createAsyncThunk(
  `${submissionStoreKey}/createSubmission`,
  async (
    {
      payload,
      resumeFile,
    }: { payload: SubmissionFormPayload; resumeFile: File | null },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await axios.post<ICandidateSubmission>(
        apiEndpoints.createSubmissionAPIPath(),
        payload,
      );

      if (resumeFile) {
        dispatch(
          uploadSubmissionResume({
            submissionId: response.data.id,
            resumeFile,
          }),
        );
      }

      dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: 'Submission has been created',
        }),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Submission hasn't been created. ${
            error?.response?.data?.message || 'Something went wrong!'
          }`,
        }),
      );

      return rejectWithValue(error.response.data);
    }
  },
);

export const updateSubmission = createAsyncThunk(
  `${submissionStoreKey}/updateSubmission`,
  async (
    {
      submissionId,
      payload,
      resumeFile,
    }: {
      submissionId: string;
      payload: SubmissionFormPayload;
      resumeFile: File | null;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await axios.put(
        apiEndpoints.updateSubmissionAPIPath(submissionId),
        payload,
      );

      if (resumeFile) {
        dispatch(
          uploadSubmissionResume({
            submissionId,
            resumeFile,
          }),
        );
      }

      dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: 'Submission has been updated',
        }),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Submission hasn't been updated. ${
            error?.response?.data?.message || 'Something went wrong!'
          }`,
        }),
      );

      return rejectWithValue(error.response.data);
    }
  },
);

export const uploadSubmissionResume = createAsyncThunk(
  `${submissionStoreKey}/uploadSubmissionResume`,
  async (
    {
      submissionId,
      resumeFile,
    }: {
      submissionId: string;
      resumeFile: File;
    },
    { rejectWithValue },
  ) => {
    try {
      const form = new FormData();
      form.append('resume', resumeFile);

      const response = await axios.post(
        apiEndpoints.uploadSubmissionResumeAPIPath(submissionId),
        form,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const setSubmissionScheduledSlot = createAsyncThunk(
  `${submissionStoreKey}/setSubmissionScheduledSlot`,
  async ({
    submissionId,
    scheduledSlot,
  }: {
    submissionId: string;
    scheduledSlot: DateTimeRange;
  }) => {
    const response = await axios.post(
      apiEndpoints.setSubmissionScheduledSlotAPIPath(submissionId),
      { scheduledSlot },
    );

    return response.data;
  },
);

export const markSubmissionAsRejected = createAsyncThunk(
  `${submissionStoreKey}/markSubmissionAsRejected`,
  async ({
    submissionId,
    closingReason,
  }: {
    submissionId: string;
    closingReason: string;
  }) => {
    const response = await axios.post(
      apiEndpoints.markSubmissionAsRejectedAPIPath(submissionId),
      { closingReason },
    );

    return response.data;
  },
);
