import { TextButton } from '@components';
import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  MobileStepper,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SendIcon from '@mui/icons-material/Send';
import {
  SubmissionStatus,
  SubmissionStep,
  SubmissionStepsOrdered,
} from '@constants';

export const SubmissionFooter: React.FC<{
  activeStep: SubmissionStep;
  isPerformingAction: boolean;
  submissionStatus: SubmissionStatus | null;
  onPreviousClick: () => void;
  onNextClick: () => void;
  onSubmitClick: () => void;
  onSaveDraftClick: () => void;
}> = ({
  activeStep,
  isPerformingAction,
  submissionStatus,
  onPreviousClick,
  onNextClick,
  onSubmitClick,
  onSaveDraftClick,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isFirstStep = SubmissionStepsOrdered[0] === activeStep;
  const isLastStep = SubmissionStepsOrdered.at(-1) === activeStep;

  return (
    <Box width="100%">
      {isMobile ? (
        <MobileStepper
          variant="text"
          steps={SubmissionStepsOrdered.length}
          activeStep={SubmissionStepsOrdered.indexOf(activeStep)}
          nextButton={
            <Button
              variant="contained"
              size="small"
              onClick={isLastStep ? onSubmitClick : onNextClick}
              disabled={isPerformingAction}
            >
              {isLastStep ? 'Submit' : 'Next'}
            </Button>
          }
          backButton={
            <Button
              variant="contained"
              size="small"
              onClick={onPreviousClick}
              disabled={isPerformingAction || isFirstStep}
              sx={{
                backgroundColor: '#3F8CFF14',
                color: '#42A5F5',
              }}
            >
              Prev.
            </Button>
          }
        />
      ) : (
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" gap={2}>
            {!isFirstStep && (
              <Button
                variant="contained"
                onClick={onPreviousClick}
                sx={{
                  height: '40px',
                  backgroundColor: '#3F8CFF14',
                  color: '#42A5F5',
                  textTransform: 'none',
                  display: 'flex',
                }}
              >
                Previous
              </Button>
            )}
            <Button
              variant="contained"
              onClick={isLastStep ? onSubmitClick : onNextClick}
              sx={{
                height: '40px',
                backgroundColor: 'text.link',
                textTransform: 'none',
                display: 'flex',
              }}
              disabled={isPerformingAction}
              endIcon={isLastStep ? <SendIcon /> : <ArrowForwardIosIcon />}
            >
              {isLastStep ? 'Submit' : 'Next'}
            </Button>
          </Box>

          {(!submissionStatus ||
            submissionStatus === SubmissionStatus.Draft) && (
            <TextButton
              onClick={onSaveDraftClick}
              variant="secondary"
              underlined
              sx={{
                fontSize: '14px',
                display: { xs: 'none', sm: 'flex', md: 'flex' },
              }}
              disabled={isPerformingAction}
            >
              Save as draft
            </TextButton>
          )}
        </Box>
      )}
    </Box>
  );
};
