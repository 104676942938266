import React from 'react';
import { format } from 'date-fns';

import { Box, Chip, Link, TableCell, Typography } from '@mui/material';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import { CandidateScore, CountryFlag } from '@components';
import { ActionReportChip } from './components/ActionReportChip';

import { getCandidateName } from '@utils';
import { IDWHCandidate } from '@types';
import { AppRoutes } from '@constants';

export const AcrionReporCandidateRow: React.FC<{
  candidate: IDWHCandidate;
}> = ({ candidate }) => {
  const handleCandidateNameClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.stopPropagation();
  };
  return (
    <>
      <TableCell align="left">
        <Box display="flex">
          <CountryFlag
            country={candidate.Location_Country}
            sx={{ mr: '0.5rem' }}
          />
          <Link
            href={`/${AppRoutes.CANDIDATES}/${candidate.id}`}
            rel="noreferrer"
            target="_blank"
            onClick={handleCandidateNameClick}
            color="text.link"
          >
            {getCandidateName({
              fullName: candidate.Full_Name,
              firstName: candidate.First_Name,
              lastName: candidate.Last_Name,
              englishFirstName: candidate.English_First_Name,
              englishLastName: candidate.English_Last_Name,
            }) || '-'}
          </Link>
        </Box>
      </TableCell>
      <TableCell align="left">{candidate.Seniority_Level || '-'}</TableCell>
      <TableCell align="left">
        <Chip label={candidate.Technical_Flow || '-'} color="secondary" />
      </TableCell>
      <TableCell align="left">{candidate.Added_by || '-'}</TableCell>
      <TableCell align="left">
        {candidate.Candidate_Status_Updated_At ? (
          <Typography variant="body1">
            {format(
              new Date(candidate.Candidate_Status_Updated_At),
              'yyyy-MM-dd',
            )}
          </Typography>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell align="left">{candidate.Candidate_Status || '-'}</TableCell>
      <TableCell align="left">
        <ActionReportChip actionReport={candidate.actionReport} />
      </TableCell>
      <TableCell align="left">
        <CandidateScore score={candidate.score} />
      </TableCell>
      <TableCell align="right">
        <NavigateNextRoundedIcon color="secondary" />
      </TableCell>
    </>
  );
};
