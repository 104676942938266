import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { candidateDetailsStoreKey } from './candidateDetails.const';
import { SnackbarType, apiEndpoints } from '@constants';
import { transformJobOpening } from '@utils';
import { setSnackbar } from '@redux/snackbar';
import {
  ICandidateMeeting,
  MeetingReviewPayload,
  CandidateTestTask,
  CandidateVideoInterview,
  ReviewVideoInterviewPayload,
  VideoInterview,
  CandidateStatusPayload,
  ICandidateProcess,
  IDWHJobOpening,
  IDWHJobOpeningRaw,
  IUserKnowledgeQuiz,
  IZohoCandidate,
  ICandidateSubmission,
  TTSentData,
  TTReviewData,
  IHotPipelineCandidatePayload,
  IDWHCandidate,
  CandidateLPInvitationAPIPayload,
  NotifyCandidatePayload,
  ZohoTask,
} from '@types';

export const fetchCandidateDuplicates = createAsyncThunk(
  `${candidateDetailsStoreKey}/fetchCandidateDuplicates`,
  async (condition: { field: keyof IDWHCandidate; value: string }) => {
    try {
      const response = await axios.get<
        Partial<Record<keyof IDWHCandidate, IDWHCandidate[]>>
      >(apiEndpoints.duplicateCadndiatesAPIPath(), {
        params: { [condition.field]: condition.value },
      });

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
    }
  },
);

export const createCandidate = createAsyncThunk(
  `${candidateDetailsStoreKey}/createCandidate`,
  async ({ data }: { data: FormData }, { dispatch }) => {
    try {
      const response = await axios.post(apiEndpoints.candidates(), data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Candidate profile successfully created.`,
        }),
      );

      return response.data;
    } catch (error: any) {
      dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Candidate profile was not created. ${
            error?.response?.data?.message || error?.response?.data?.error
          }`,
        }),
      );

      throw error;
    }
  },
);

export const fetchCandidateDetails = createAsyncThunk(
  `${candidateDetailsStoreKey}/fetchCandidateDetails`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<IZohoCandidate>(
        apiEndpoints.candidateById(id),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const updateCandidateProfile = createAsyncThunk(
  `${candidateDetailsStoreKey}/updateCandidateProfile`,
  async (
    { candidateId, data }: { candidateId: string; data: FormData },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await axios.patch(
        apiEndpoints.candidateById(candidateId),
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Candidate profile successfully updated.`,
        }),
      );

      return response.data;
    } catch (error: any) {
      dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Candidate profile was not updated. ${error.message}`,
        }),
      );

      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchCandidateJobOpenings = createAsyncThunk(
  `${candidateDetailsStoreKey}/fetchCandidateJobOpenings`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<IDWHJobOpeningRaw[]>(
        apiEndpoints.candidateJobOpenings(id),
      );

      const data: IDWHJobOpening[] = response.data.map(transformJobOpening);

      return data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchAvailableVideoInterview = createAsyncThunk(
  `${candidateDetailsStoreKey}/fetchAvailableVideoInterview`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<VideoInterview[]>(
        apiEndpoints.candidateGetAvailableVideoInterview(id),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchCandidateUserVideoInterview = createAsyncThunk(
  `${candidateDetailsStoreKey}/fetchCandidateUserVideoInterview`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<CandidateVideoInterview[]>(
        apiEndpoints.candidateVideoInterview(id),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const generateClientVideoLink = createAsyncThunk(
  `${candidateDetailsStoreKey}/generateClientVideoLink`,
  async (
    {
      candidateId,
      videoInterviewId,
    }: {
      candidateId: string;
      videoInterviewId: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await axios.post(
        apiEndpoints.candidateGenerateClientVideoLink(
          candidateId,
          videoInterviewId,
        ),
      );

      dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: 'Link generated',
        }),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Unable to generate client video interview link`,
        }),
      );
      return rejectWithValue(error.response.data);
    }
  },
);

export const postCandidateResetVideoInterview = createAsyncThunk(
  `${candidateDetailsStoreKey}/postCandidateResetVideoInterview`,
  async (
    {
      candidateId,
      videoInterviewId,
    }: {
      candidateId: string;
      videoInterviewId: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await axios.post<CandidateVideoInterview[]>(
        apiEndpoints.candidateResetVideoInterview(
          candidateId,
          videoInterviewId,
        ),
        {},
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Unable to reset candidate video interview`,
        }),
      );

      return rejectWithValue(error.response.data);
    }
  },
);

export const postCandidateSendVideoInterview = createAsyncThunk(
  `${candidateDetailsStoreKey}/postCandidateSendVideoInterview`,
  async (
    {
      candidateId,
      videoInterviewId,
    }: {
      candidateId: string;
      videoInterviewId: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await axios.post<CandidateVideoInterview[]>(
        apiEndpoints.candidateSendVideoInterview(candidateId, videoInterviewId),
        {},
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Unable to send candidate video interview`,
        }),
      );

      return rejectWithValue(error.response.data);
    }
  },
);

export const postUserVideoInterviewReview = createAsyncThunk(
  `${candidateDetailsStoreKey}/postUserVideoInterviewReview`,
  async ({
    candidateId,
    videoInterviewId,
    presentationSkillsLevel,
    functionalExpertiseLevel,
    englishLevel,
    feedback,
    resolution,
    isPassed,
  }: {
    candidateId: string;
    videoInterviewId: string;
  } & ReviewVideoInterviewPayload) => {
    try {
      const response = await axios.post<CandidateVideoInterview>(
        apiEndpoints.candidateVideoInterviewReview(
          candidateId,
          videoInterviewId,
        ),
        {
          presentationSkillsLevel,
          functionalExpertiseLevel,
          englishLevel,
          feedback,
          isPassed,
          resolution,
        },
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchCandidateKnowledgeQuiz = createAsyncThunk(
  `${candidateDetailsStoreKey}/fetchCandidateKnowledgeQuiz`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<IUserKnowledgeQuiz>(
        apiEndpoints.candidateKnowledgeQuiz(id),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchCandidateTestTasks = createAsyncThunk(
  `${candidateDetailsStoreKey}/fetchCandidateTestTasks`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<CandidateTestTask[]>(
        apiEndpoints.getUserTestTasksAPIPath(id),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const updateCandidateTestTask = createAsyncThunk(
  `${candidateDetailsStoreKey}/updateCandidateTestTask`,
  async (
    {
      candidateId,
      candidateTestTaskId,
      data,
    }: { candidateId: string; candidateTestTaskId: string; data: FormData },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await axios.put<CandidateTestTask>(
        apiEndpoints.updateUserTestTaskAPIPath(
          candidateId,
          candidateTestTaskId,
        ),
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Candidate test task successfully updated.`,
        }),
      );

      return response.data;
    } catch (error: any) {
      dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Candidate test task was not updated. ${error.message}`,
        }),
      );

      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const sendTestTaskToCandidate = createAsyncThunk(
  `${candidateDetailsStoreKey}/sendTestTaskToCandidate`,
  async ({ candidateId, ...data }: TTSentData) => {
    try {
      const response = await axios.post<CandidateTestTask>(
        apiEndpoints.sendUserTestTaskAPIPath(candidateId),
        data,
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const postTestTaskReview = createAsyncThunk(
  `${candidateDetailsStoreKey}/postTestTaskReview`,
  async ({ candidateId, userTestTaskId, ...body }: TTReviewData) => {
    try {
      const response = await axios.post<CandidateTestTask>(
        apiEndpoints.reviewUserTestTaskAPIPath(candidateId, userTestTaskId),
        body,
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const associateCandidateWithJobOpening = createAsyncThunk(
  `${candidateDetailsStoreKey}/associateCandidateWithJobOpening`,
  async (
    {
      candidateId,
      jobOpeningId,
    }: {
      candidateId: string;
      jobOpeningId: string;
    },
    thunkAPI,
  ) => {
    try {
      const response = await axios.post<CandidateVideoInterview>(
        apiEndpoints.candidateJobOpeningAssociation(candidateId),
        {
          jobId: jobOpeningId,
        },
      );

      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Association has been created.`,
        }),
      );

      return response.data;
    } catch (error: any) {
      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Association hasn't been created. ${error.message}`,
        }),
      );
      throw error;
    }
  },
);

export const unAssociateCandidateWithJobOpening = createAsyncThunk(
  `${candidateDetailsStoreKey}/unAssociateCandidateWithJobOpening`,
  async (
    {
      candidateId,
      jobOpeningId,
    }: {
      candidateId: string;
      jobOpeningId: string;
    },
    thunkAPI,
  ) => {
    try {
      const response = await axios.delete<CandidateVideoInterview>(
        apiEndpoints.candidateJobOpeningUnAssociation(
          candidateId,
          jobOpeningId,
        ),
      );

      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Association has been removed.`,
        }),
      );

      return response.data;
    } catch (error: any) {
      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Association hasn't been removed. ${error.message}`,
        }),
      );
      throw error;
    }
  },
);

export const fetchCandidateMeetings = createAsyncThunk(
  `${candidateDetailsStoreKey}/fetchCandidateMeetings`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<ICandidateMeeting[]>(
        apiEndpoints.candidateMeetings(id),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const postCandidateMeetingReview = createAsyncThunk(
  `${candidateDetailsStoreKey}/postCandidateMeetingReview`,
  async (
    {
      candidateId,
      meetingId,
      ...payload
    }: {
      candidateId: string;
      meetingId: string;
    } & MeetingReviewPayload,
    thunkAPI,
  ) => {
    try {
      const response = await axios.post<ICandidateMeeting>(
        apiEndpoints.candidateMeetingReview(candidateId, meetingId),
        payload,
      );

      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: 'Meeting has been reviewed',
        }),
      );

      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: "Meeting hasn't been reviewed",
        }),
      );
      throw error;
    }
  },
);

export const sendSuitablePositionsToZohoProfile = createAsyncThunk(
  `${candidateDetailsStoreKey}/sendSuitablePositionsToZohoProfile`,
  async (
    {
      candidateId,
      positionIds,
    }: { candidateId: string; positionIds: string[] },
    thunkAPI,
  ) => {
    try {
      const response = await axios.post<CandidateVideoInterview>(
        apiEndpoints.candidateZohoSuitablePositions(candidateId),
        { positionIds },
      );

      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Suitable positions are updated in Zoho candidate's profile.`,
        }),
      );

      return response.data;
    } catch (error: any) {
      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Suitable positions are NOT updated in Zoho candidate's profile. ${error.message}`,
        }),
      );
      throw error;
    }
  },
);

export const changeCandidateStatus = createAsyncThunk(
  `${candidateDetailsStoreKey}/changeCandidateStatus`,
  async (data: CandidateStatusPayload, thunkAPI) => {
    try {
      const response = await axios.post(
        apiEndpoints.candidateStatus(data.candidateId),
        data.payload,
      );

      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Candidate status successfully updated.`,
        }),
      );

      return response.data;
    } catch (error: any) {
      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Candidate status was not updated. ${error.message}`,
        }),
      );
      throw error;
    }
  },
);

export const notifyCandidate = createAsyncThunk(
  `${candidateDetailsStoreKey}/notifyCandidate`,
  async ({
    candidateId,
    ...payload
  }: { candidateId: string } & NotifyCandidatePayload) => {
    try {
      const response = await axios.post(
        apiEndpoints.notifyCandidateAPIPath(candidateId),
        payload,
      );

      return response.data;
    } catch (error: any) {
      throw error;
    }
  },
);

export const createZohoTask = createAsyncThunk(
  `${candidateDetailsStoreKey}/notifyCandidate`,
  async (payload: ZohoTask) => {
    try {
      const response = await axios.post(
        apiEndpoints.createZohoTaskAPIPath(),
        payload,
      );

      return response.data;
    } catch (error: any) {
      throw error;
    }
  },
);

export const fetchCandidateProcessActivity = createAsyncThunk(
  `${candidateDetailsStoreKey}/fetchCandidateProcessActivity`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<ICandidateProcess[]>(
        apiEndpoints.candidateProcessActivity(id),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

// Submissions
export const fetchCandidateSubmissions = createAsyncThunk(
  `${candidateDetailsStoreKey}/fetchCandidateSubmissions`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<ICandidateSubmission[]>(
        apiEndpoints.candidateSubmissions(id),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const sendInviteCandidateInHotpipeline = createAsyncThunk(
  `${candidateDetailsStoreKey}/sendInviteCandidateInHotpipeline`,
  async (data: IHotPipelineCandidatePayload, thunkAPI) => {
    try {
      const response = await axios.post<IZohoCandidate>(
        apiEndpoints.candidateInviteInHotpipeline(),
        data.payload,
      );

      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Email Invite send successfully`,
        }),
      );

      return response.data;
    } catch (error: any) {
      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Unable to send the email ${error.message}`,
        }),
      );
      throw error;
    }
  },
);

export const sendLPInvitation = createAsyncThunk(
  `${candidateDetailsStoreKey}/sendLPInvitation`,
  async (
    { candidateId, ...data }: CandidateLPInvitationAPIPayload,
    thunkAPI,
  ) => {
    try {
      const response = await axios.post(
        apiEndpoints.candidateLPInvitationAPIPath(candidateId),
        data,
      );

      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Email Invite send successfully`,
        }),
      );

      return response.data;
    } catch (error: any) {
      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Unable to send the email ${error.message}`,
        }),
      );
      throw error;
    }
  },
);
