import { removeUndefinedAttributes } from './object';
import { getNameFromEmail } from './candidates';
import { CandidateTypeOfOutboundChannel } from '@constants';
import {
  CreateCandidateAPIPayload,
  CreateCandidateFormFields,
  IProfile,
} from '@types';

export const getCreateCandidateFormInitialValues = (
  profile: IProfile | null,
): CreateCandidateFormFields => {
  const fullName = getNameFromEmail(profile?.email);
  return {
    LinkedIn_URL: null,
    Email: null,
    First_Name: null,
    Last_Name: null,
    Djinni_URL: null,
    GitHub_Link: null,
    Phone: null,
    English_level: null,
    Seniority_Level: null,
    'Dev_-_QA-AQA': null,
    Type_of_Developer: null,
    Technical_Flow: null,
    Primary_Skill_Set: [],
    Secondary_Skill_Set: [],
    Location_Country: null,
    Hourly_Rate_Expected: null,
    Leads_reply_2018: null,
    Type_of_channel: 'Outbound',
    Type_of_Outbound_channel: CandidateTypeOfOutboundChannel.Selfgen,
    Leads_owner_2018: `${fullName?.firstName} ${fullName?.lastName}`,
    Added_by: `${fullName?.firstName} ${fullName?.lastName}`,
    Self_gen_Touch_Date: null,
    resume: null,
  };
};

export const transformCrateCandidateFormValuesToPayload = (
  formData: CreateCandidateFormFields,
): CreateCandidateAPIPayload => {
  return removeUndefinedAttributes({
    LinkedIn_URL: formData.LinkedIn_URL,
    Email: formData.Email,
    First_Name: formData.First_Name,
    Last_Name: formData.Last_Name,
    Djinni_URL: formData.Djinni_URL,
    GitHub_Link: formData.GitHub_Link,
    Phone: formData.Phone,
    English_level: formData.English_level,
    Seniority_Level: formData.Seniority_Level,
    'Dev_-_QA-AQA': formData['Dev_-_QA-AQA'],
    Type_of_Developer: formData.Type_of_Developer,
    Technical_Flow: formData.Technical_Flow,
    Primary_Skill_Set: formData.Primary_Skill_Set,
    Secondary_Skill_Set: formData.Secondary_Skill_Set,
    Location_Country: formData.Location_Country,
    Hourly_Rate_Expected: formData.Hourly_Rate_Expected,
    Leads_reply_2018: formData.Leads_reply_2018,
    Type_of_channel: formData.Type_of_channel,
    Type_of_Outbound_channel: formData.Type_of_Outbound_channel,
    Added_by: formData.Added_by,
    Leads_owner_2018: formData.Leads_owner_2018,
    Self_gen_Touch_Date: formData.Self_gen_Touch_Date,
  });
};
