import { Box, Typography } from '@mui/material';

export const CDSubmissionInfoBanner: React.FC<{
  title: string;
  content: string;
}> = ({ title, content }) => (
  <Box
    sx={{
      bgcolor: (theme) => theme.palette.highlight.accent,
      padding: '.8rem',
      gap: '0.3rem',
      borderRadius: '6px',
      display: 'flex',
      flexDirection: 'column',
      mb: '20px',
    }}
  >
    <Typography variant="body2" fontWeight={'500'}>
      {title}
    </Typography>
    <Typography variant="body2">{content}</Typography>
  </Box>
);
