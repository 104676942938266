import axios from 'axios';
import { apiEndpoints } from '@constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { actionReportStoreKey } from './action-report.const';
import { IDWHCandidateRaw } from '@types';
import { transformCandidate } from '@utils';

export const fetchActionReport = createAsyncThunk(
  `${actionReportStoreKey}/fetchActionReport`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<{
        data: IDWHCandidateRaw[];
        totalCount: number;
      }>(apiEndpoints.getActionReport());

      return {
        ...response.data,
        data: response.data.data.map(transformCandidate),
      };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);
