import { Flex } from '@components';
import { CrucialRequirementsItem } from './Item';

import { JobOpeningCrucialRequirementPriority } from '@constants';
import { IJobOpeningCrucialRequirement } from '@types';

export const CrucialRequirementsList: React.FC<{
  data: IJobOpeningCrucialRequirement[] | null;
  onChangePriority: (
    requirement: string,
    priority: JobOpeningCrucialRequirementPriority,
  ) => void;
  onRemoveItem: (requirement: string) => void;
}> = ({ data, onChangePriority, onRemoveItem }) => {
  if (!data?.length) return null;

  return (
    <Flex flexDirection="column" gap={1}>
      {data.map((requirement) => (
        <CrucialRequirementsItem
          key={requirement.name}
          name={requirement.name}
          category={requirement.category}
          priority={requirement.priority}
          onChangePriority={onChangePriority}
          onRemoveItem={onRemoveItem}
        />
      ))}
    </Flex>
  );
};
