import { isEmpty } from 'lodash';

import { Box } from '@mui/material';
import { Flex } from '@components';
import { CDSubmissionHeader } from './components/CDSubmissionHeader';
import { CDSubmissionVideoInterview } from './components/CDSubmissionVideoInterview';
import { CDCandidateDetailsSectionLayout } from './components/CDSectionLayout';
import { CDSubmissionFeedback } from './components/CDSubmissionFeedback';
import { CDSubmissionPositions } from './components/CDSubmissionPositions';
import { CDSubmissionTestTask } from './components/CDSubmissionTestTask';
import { CDSubmissionProfile } from './components/CDSubmissionProfile';

import { SubmissionFormik } from '@types';
import { getSubmissionPayloadData } from '@utils';
import { SubmissionStep } from '@constants';
import { CDSubmissionInfoBanner } from './components/CDSubmissionInfoBanner';

export const SubmissionReviewStep: React.FC<{
  formik: SubmissionFormik;
  candidateId: string;
}> = ({ formik, candidateId }) => {
  const submission = getSubmissionPayloadData(
    candidateId,
    formik.values,
    SubmissionStep.Review,
  );

  return (
    <Box
      sx={{
        padding: {
          xs: '0px',
          sm: '0.5rem',
          md: '1rem',
        },
      }}
    >
      <CDSubmissionInfoBanner
        title="Preview submission"
        content="This is the candidate's profile the client will recieve"
      />
      <CDSubmissionHeader submission={submission} />
      <Flex flexDirection="column" gap={1}>
        <CDSubmissionVideoInterview submission={submission} />
        <CDCandidateDetailsSectionLayout
          title="Feedback from our interviewers"
          isHidden={!submission.feedback?.feedback}
          content={<CDSubmissionFeedback submission={submission} />}
        />
        <CDCandidateDetailsSectionLayout
          title="Matching positions for your projects"
          isHidden={!submission.positionIds?.length}
          content={<CDSubmissionPositions submission={submission} />}
        />
        <CDCandidateDetailsSectionLayout
          title="Test task"
          isHidden={!submission.testTasks?.length}
          content={<CDSubmissionTestTask submission={submission} />}
        />
        <CDCandidateDetailsSectionLayout
          title="Profile"
          isHidden={isEmpty(submission.profile)}
          content={<CDSubmissionProfile submission={submission} />}
        />
      </Flex>
    </Box>
  );
};
