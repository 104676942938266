import { SyntheticEvent, useMemo } from 'react';
import { flatten, isString, values } from 'lodash';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Typography } from '@mui/material';
import { Flex, AutocompleteTextfield } from '@components';

import { getCandidateStages, transormCandidateStatus } from '@utils';
import {
  AcceptedAnotherOfferStatuses,
  CandidateStatus,
  CandidateStatusStage,
  CandidateStatusesByStage,
  ClosingStatusesByStage,
  DisqualifiedStatuses,
  HiddenCandidateStatuses,
  NoSuitableOpeningsStatuses,
  NonStageRelatedStatuses,
  PassedStatusesByStage,
  ProcessStatusesByStage,
} from '@constants';

export const StatusTransition: React.FC<{
  title: string;
  from: { status: CandidateStatus; stage: CandidateStatusStage };
  to: { status: CandidateStatus; stage: CandidateStatusStage };
  canidateSpecialization: string | null;
  disabled?: boolean;
  onChange: (
    stage: CandidateStatusStage | '',
    status: CandidateStatus | '',
  ) => void;
}> = ({ title, from, to, disabled, canidateSpecialization, onChange }) => {
  const nextStages = useMemo(
    () => getCandidateStages(canidateSpecialization),
    [canidateSpecialization],
  );
  const nextStatusOptions = useMemo(() => {
    const processStatuses = flatten(values(ProcessStatusesByStage));
    const passedStatuses = flatten(values(PassedStatusesByStage));
    const closingStatuses = flatten(values(ClosingStatusesByStage));

    return to.stage
      ? CandidateStatusesByStage[to.stage]
          .filter((status) => !HiddenCandidateStatuses.includes(status))
          .map((status) => ({
            status,
            group: processStatuses.includes(status)
              ? 'Process'
              : passedStatuses.includes(status)
              ? 'Passed'
              : closingStatuses.includes(status)
              ? 'Closed: Stage-related'
              : AcceptedAnotherOfferStatuses.includes(status)
              ? 'Closed: Accepted another offer'
              : DisqualifiedStatuses.includes(status)
              ? 'Closed: Disqualified'
              : NoSuitableOpeningsStatuses.includes(status)
              ? 'Closed: No suitable openings'
              : NonStageRelatedStatuses.includes(status)
              ? 'Closed: Non-stage related'
              : 'Other',
          }))
      : [];
  }, [to.stage]);

  const onNextStageChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: any,
  ) => onChange(newValue || '', '');

  const onNextStatusChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: any,
  ) => onChange(to.stage, newValue?.status || '');

  return (
    <Flex flexDirection="column" gap={2} alignItems="start">
      <Typography>
        <b>{title}</b> status transition
      </Typography>
      <Flex justifyContent="space-between" gap={2}>
        <Flex justifyContent="space-between" gap={2}>
          <Box flex={1}>
            <AutocompleteTextfield
              value={from.stage || null}
              label="Current stage"
              fullWidth
              disabled
            />
          </Box>
          <Box flex={2}>
            <AutocompleteTextfield
              value={transormCandidateStatus(from.status) || null}
              label="Current status"
              fullWidth
              disabled
            />
          </Box>
        </Flex>

        <ArrowForwardIcon />

        <Flex justifyContent="space-between" gap={2}>
          <Box flex={1}>
            <AutocompleteTextfield
              options={nextStages}
              value={to.stage || null}
              onChange={onNextStageChange}
              label="Next stage"
              fullWidth
              disabled={disabled}
            />
          </Box>
          <Box flex={2}>
            <AutocompleteTextfield
              options={nextStatusOptions}
              value={to.status || null}
              getOptionLabel={(option) =>
                isString(option)
                  ? transormCandidateStatus(option)
                  : transormCandidateStatus(option.status)
              }
              groupBy={(option) => option.group}
              onChange={onNextStatusChange}
              getOptionDisabled={(option) => option.status === from.status}
              label="Next status"
              fullWidth
              disabled={disabled}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
