import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';

interface IProps {
  position?: string;
}

export const Loader: React.FC<IProps> = ({ position }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: position || 'absolute',
        width: '100%',
        height: '100%',
      }}
      data-testid="is-loading"
    >
      <CircularProgress sx={{ color: (theme) => theme.palette.brand.accent }} />
    </Box>
  );
};
