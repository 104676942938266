import {
  IEntityMainTableHeaderCell,
  IJobOpeningDetails,
  IJobOpeningOptions,
} from '@types';
import { TableHeaderCellAligment } from './table';

export enum JobOpeningsPriority {
  HighPlus = 'High+',
  High = 'High',
  NormalPlus = 'Normal+',
  Normal = 'Normal',
  Low = 'Low',
  Lowest = 'Lowest',
  PreOpen = 'Pre-open',
  OnHold = 'On-hold',
}

export enum JobOpeningsGroupingOptions {
  NONE = 'None',
  ACCOUNT = 'Client_Name',
  TECHNICAL_FLOW = 'TechnicalFlow',
  Priority = 'Priority',
  Recruiter = 'Client_Recruiter',
}

export const JobOpeningsGroupOptions = [
  { label: 'Technical flow', value: JobOpeningsGroupingOptions.TECHNICAL_FLOW },
  { label: 'Priority', value: JobOpeningsGroupingOptions.Priority },
  { label: 'Account', value: JobOpeningsGroupingOptions.ACCOUNT },
  { label: 'Recruiter', value: JobOpeningsGroupingOptions.Recruiter },
  { label: 'No grouping', value: JobOpeningsGroupingOptions.NONE },
];

export enum JobOpeningsSortingOptions {
  PRIORITY = 'Priority',
  JOB_OPENING_NAME = 'Job_Opening_Name',
  ACCOUNT = 'Client_Name',
  DATE_OPENED_OFFICIAL = 'Date_Opened_Official',
  LOCATION_AREA = 'Location_Area',
  TECH_FLOW = 'TechnicalFlow',
  SENIORITY = 'Seniority_Level',
  CLIENT_RECRUITER = 'Client_Recruiter',
  SUBMISSIONS_NUMBER = 'Number_of_submissions',
}

export const JobOpeningFilterFields: Array<keyof IJobOpeningOptions> = [
  'priority',
  'client',
  'locations',
  'techFlow',
  'seniorities',
  'clientRecruiters',
];

export const JOB_OPENING_SENIORITY_LEVELS = [
  'Trainee',
  'Junior I',
  'Junior II',
  'Middle I',
  'Middle II',
  'Senior I',
  'Senior II',
  'Lead',
  'Architect',
  'С-level',
];

export enum CandidateDetailsByStageSortingOptions {
  Score = 'score.total',
}

export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum JobOpeningStatusFilter {
  Open = 'open',
  OnHold = 'on-hold',
}

export const JobOpeningStatusFilterLabel: Record<
  JobOpeningStatusFilter,
  string
> = {
  [JobOpeningStatusFilter.Open]: 'Open positions',
  [JobOpeningStatusFilter.OnHold]: 'Positions on hold',
};

export const tableHeadersJobOpenings: IEntityMainTableHeaderCell[] = [
  {
    label: 'Priority',
    value: JobOpeningsSortingOptions.PRIORITY,
    width: 62,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Position',
    value: JobOpeningsSortingOptions.JOB_OPENING_NAME,
    width: 330,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Account',
    value: JobOpeningsSortingOptions.ACCOUNT,
    width: 150,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Days',
    value: JobOpeningsSortingOptions.DATE_OPENED_OFFICIAL,
    width: 70,
    align: TableHeaderCellAligment.Right,
    isSortable: true,
  },
  {
    label: 'Locations',
    value: JobOpeningsSortingOptions.LOCATION_AREA,
    width: 134,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Tech flow',
    value: JobOpeningsSortingOptions.TECH_FLOW,
    width: 220,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Seniority',
    value: JobOpeningsSortingOptions.SENIORITY,
    width: 220,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Client recruiter',
    value: JobOpeningsSortingOptions.CLIENT_RECRUITER,
    width: 150,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Number of submissions',
    value: JobOpeningsSortingOptions.SUBMISSIONS_NUMBER,
    width: 150,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
];

export enum JobOpeningReasonOfClosing {
  OnHold = 'On-Hold',
  ClientRequirementsChanged = 'Client: change of requirements/priorities',
  ClientBudgetChanged = 'Client: change/hold of budget',
  ClientHiredOnsite = 'Client: hired onsite/with another vendor',
  ClientLongSearch = 'Client: long recruiting search',
  InternalRequirementsChanged = 'Internal: change of requirements/priorities',
  InternalBudgetChanged = 'Internal: change/hold of budget',
  PotentialBudgetChanged = 'Potential: change/hold of budget',
  PotentialRequirementsChanged = 'Potential: change/hold of requirements/priorities',
  PotentialHiredOnsite = 'Potential: hired onsite/with another vendor',
  PotentialLongSearch = 'Potential: long recruiting search',
  PotentialMSANotSigned = 'Potential: MSA not signed',
  Other = 'Other',
  Hired = 'Hired',
  HiredFromInstantOffer = 'Hired from Instant Offer',
  HiredFromNewHome = 'Hired from New Home',
}

export enum JobOpeningStatus {
  InProgress = 'In-progress',
  PreOpen = 'Pre-Open',
  Closed = 'Cancelled2',
  Filled = 'Filled',
}

export const GrouppedJobOpeningCLosingReasons: Record<
  JobOpeningStatus,
  JobOpeningReasonOfClosing[]
> = {
  [JobOpeningStatus.Closed]: [
    JobOpeningReasonOfClosing.ClientRequirementsChanged,
    JobOpeningReasonOfClosing.ClientBudgetChanged,
    JobOpeningReasonOfClosing.ClientHiredOnsite,
    JobOpeningReasonOfClosing.ClientLongSearch,
    JobOpeningReasonOfClosing.InternalRequirementsChanged,
    JobOpeningReasonOfClosing.InternalBudgetChanged,
    JobOpeningReasonOfClosing.PotentialBudgetChanged,
    JobOpeningReasonOfClosing.PotentialRequirementsChanged,
    JobOpeningReasonOfClosing.PotentialHiredOnsite,
    JobOpeningReasonOfClosing.PotentialLongSearch,
    JobOpeningReasonOfClosing.PotentialMSANotSigned,
    JobOpeningReasonOfClosing.Other,
  ],
  [JobOpeningStatus.Filled]: [
    JobOpeningReasonOfClosing.Hired,
    JobOpeningReasonOfClosing.HiredFromInstantOffer,
    JobOpeningReasonOfClosing.HiredFromNewHome,
  ],
  [JobOpeningStatus.PreOpen]: [],
  [JobOpeningStatus.InProgress]: [],
};

export enum JobOpeningState {
  Open = 'Open',
  PreOpen = 'PreOpen',
  OnHold = 'OnHold',
}

export const JobOpeningFieldsLabels: {
  [k in keyof IJobOpeningDetails]: string;
} = {
  id: 'Id',
  Job_Opening_Status: 'Job opening status',
  Seniority_level: 'Seniority level',
  Location_Area_Test: 'Location area',
  Technical_Flow: 'Technical flow',
  LP_Specializations: 'LP specializations',
  Specialization: 'Specialization',
  Type: 'Type of position',
  Client_Name: 'Client name',
  Job_Opening_Name: 'Posting title',
  Stack_for_tech_positions: 'Stack (for tech positions)',
  Careers_site_link: 'Careers site link',
  Hiring_M: 'Hiring Manager',
  Hiring_Manager_email: 'Hiring Manager email',
  Priority: 'Priority',
  Days_since_opening_new: 'Days since opening',
  Job_Opening_ID: 'Job opening ID',
  No_of_Candidates_Associated: 'Number of associated candidates',
  Date_Opened: 'Date opened',
  Date_Opened_Official: 'Date opened official',
  Date_Pre_opened: 'Date pre-opened',
  Date_Closed: 'Date closed',
  Location_Tier_Outreach: 'Location tier outreach',
  Number_of_Positions: 'Number of positions',
  No_of_Candidates_Hired: 'Number of candidates hired',
  Number_of_submissions: 'Number of submissions',
  Recruiter: 'Recruiter',
  Second_recruiter: 'Second recruiter',
  Client_Partner: 'Client partner',
  Responsible_Recruiter: 'Client recruiter',
  Candidate_Recruiter: 'Candidate recruiter',
  Google_Drive_Folder: 'JD link',
  TI_Requirements_Link: 'TI requirements link',
  City_Location: 'Location',
  Timezone_Requirements: 'Timezone requirements',
  Must_Haves1: 'Must haves',
  Multi_Line_4: 'Main tasks',
  Multi_Line_5: 'Nice to haves',
  Test_Task: 'Test task',
  Subteam_Name: 'Subteam name',
  Delivery_Manager: 'Delivery Manager',
  Technical_Reviewer: 'Technical interviewer',
  Additional_Technical_Interviewer: 'Additional technical interviewer',
  Other_Comments: 'Other comments',
  Technical_Interviewers_Not_in_list: 'Technical interviewers not in the list',
  Multi_Select_13: 'Interview steps',
  Position_type: 'Source of the position',
  JO_changed_via_Form_by: 'JO changed via Form by',
  Reason_of_Closing: 'Reason of closing',
  On_hold_date: 'On-hold on',
  Client_submission_emails: 'Client submission emails',
  Project_interview_type: 'Project interview type',
  PDM: 'PDM',
  Client_Name_New: 'Client name',
  Client_Name_for_Report: 'Client name for report',
  Account_Manager: 'Account Manager',
  Job_Post_need_update: 'Job post need update',
  Date_Zoho_update_done: 'Date Zoho update done',
  Job_Post_done_update: 'Job post done update',
  Date_Platforms_update_done: 'Date platforms update done',
  First_submission: 'First submission',
  Candidate_Start_Date: 'Candidate start date',
  Location_of_Closing: 'Location of closing',
  Seniority_of_closing: 'Seniority of closing',
  On_hold_off: 'On-hold off',
  Days_for_closing: 'Days for closing',
  Interview_process: 'Position comments',
  Candidates_spreadsheet: 'Candidate spreadsheet',
  Jira_url: 'JIRA ticket',
  availableCalls: 'Available intro calls',
  maxCalls: 'Max intro calls',
  crucialRequirements: 'Crucial requirements',
};

export const JobOpeningFieldsPlaceholders: {
  [k in keyof IJobOpeningDetails]?: string;
} = {
  TI_Requirements_Link: 'Add shared document link',
  Candidates_spreadsheet: 'Add shared document link',
  Jira_url: 'Add JIRA ticket link coming from the client',
};

export enum JobOpeningCrucialRequirementPriority {
  NiceToHave = 'niceToHave',
  MustHave = 'mustHave',
}

export const JobOpeningCrucialRequirementPriorityLabel: Record<
  JobOpeningCrucialRequirementPriority,
  string
> = {
  [JobOpeningCrucialRequirementPriority.MustHave]: 'Must-have',
  [JobOpeningCrucialRequirementPriority.NiceToHave]: 'Nice-to-have',
};

export enum JobOpeningCrucialRequirementCategory {
  Education = 'education',
  Technology = 'technology',
  DomainKnowledge = 'domainKnowledge',
  Visa = 'visa',
}

export const JobOpeningCrucialRequirementCategoryLabel: Record<
  JobOpeningCrucialRequirementCategory,
  string
> = {
  [JobOpeningCrucialRequirementCategory.Education]: 'Education degree',
  [JobOpeningCrucialRequirementCategory.Technology]:
    'Technologies, frameworks, and tools',
  [JobOpeningCrucialRequirementCategory.DomainKnowledge]:
    'Business domain knowledge',
  [JobOpeningCrucialRequirementCategory.Visa]: 'Visa or work-permit',
};

export const JobOpeningCrucialRequirementCategoryOrder: JobOpeningCrucialRequirementCategory[] =
  [
    JobOpeningCrucialRequirementCategory.Technology,
    JobOpeningCrucialRequirementCategory.Education,
    JobOpeningCrucialRequirementCategory.DomainKnowledge,
    JobOpeningCrucialRequirementCategory.Visa,
  ];
