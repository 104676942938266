import { useCallback, useMemo, useState } from 'react';

import { Flex } from '@components';
import { CrucialRequirementsEmptyState } from './components/EmptyState';
import { CrucialRequirementsSearch } from './components/Search';
import { CrucialRequirementsList } from './components/List';
import { AddNewCrucialRequirementModal } from './components/AddNewCrucialRequirementModal';

import { IJobOpeningCrucialRequirement, JobOpeningFormik } from '@types';
import { isTruthy } from '@utils';
import { JobOpeningCrucialRequirementPriority } from '@constants';
import { Typography } from '@mui/material';

export const CrucialRequirements: React.FC<{
  formik: JobOpeningFormik;
}> = ({ formik }) => {
  const [isAddMode, setIsAddMode] = useState(
    !!formik.values.crucialRequirements?.length,
  );
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [defaultAddRequirement, setDefaultAddRequirement] = useState('');

  const selectedRequirements = useMemo(
    () => formik.values.crucialRequirements?.map((e) => e.name) || [],
    [formik.values.crucialRequirements],
  );
  const onSelectRequirement = useCallback(
    (requirement: IJobOpeningCrucialRequirement) => {
      setIsAddModalOpen(false);
      setDefaultAddRequirement('');
      formik.setFieldValue(
        'crucialRequirements',
        ([] as IJobOpeningCrucialRequirement[])
          .concat(
            formik.values
              .crucialRequirements as IJobOpeningCrucialRequirement[],
          )
          .concat(requirement)
          .filter(isTruthy),
      );
    },
    [formik.values.crucialRequirements],
  );
  const onAddRequirement = useCallback((name: string) => {
    setIsAddModalOpen(true);
    setDefaultAddRequirement(name);
  }, []);
  const onChangeRequirementPriority = useCallback(
    (name: string, priority: JobOpeningCrucialRequirementPriority) => {
      formik.setFieldValue(
        'crucialRequirements',
        formik.values.crucialRequirements?.map((e) =>
          e.name === name ? { ...e, priority } : e,
        ),
      );
    },
    [formik.values.crucialRequirements],
  );
  const onRemoveRequirement = useCallback(
    (name: string) => {
      formik.setFieldValue(
        'crucialRequirements',
        formik.values.crucialRequirements?.filter((e) => e.name !== name),
      );
    },
    [formik.values.crucialRequirements],
  );

  if (!formik.values.crucialRequirements?.length && !isAddMode) {
    return <CrucialRequirementsEmptyState onClick={() => setIsAddMode(true)} />;
  }

  return (
    <>
      <Flex
        sx={(theme) => ({
          border: `5px solid ${theme.palette.background.backgroundPrimary}`,
          borderRadius: '8px',
        })}
        flexDirection="column"
        alignItems="start"
        gap={1}
        p={4}
      >
        <Typography fontWeight={500}>Crucial requirements</Typography>
        <CrucialRequirementsSearch
          selectedRequirements={selectedRequirements}
          onSelect={onSelectRequirement}
          onAdd={onAddRequirement}
        />
        <CrucialRequirementsList
          data={formik.values.crucialRequirements}
          onChangePriority={onChangeRequirementPriority}
          onRemoveItem={onRemoveRequirement}
        />
      </Flex>
      <AddNewCrucialRequirementModal
        isOpen={isAddModalOpen}
        defaultName={defaultAddRequirement}
        onSuccessfulCreate={onSelectRequirement}
        onClose={() => setIsAddModalOpen(false)}
      />
    </>
  );
};
