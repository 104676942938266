import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '@redux/hooks';

import VideoCallOutlinedIcon from '@mui/icons-material/VideoCallOutlined';
import { Stack, Typography } from '@mui/material';

import {
  RowLayout,
  CustomButton,
  HotPipelineInviteCandidateModal,
} from '@components';

import { candidateDetailsSelectors } from '@redux/candidateDetails';
import { authSelectors } from '@redux/auth';

import {
  formatAndAddDaysToDate,
  getDaysBetweenDates,
  checkUserCanGenerateTILink,
} from '@utils';

export const HotPipelineStatus: React.FC = () => {
  const location = useLocation();

  const candidateDetails = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );
  const profile = useAppSelector(authSelectors.getProfileData);
  const isAbleToGenerateLink = checkUserCanGenerateTILink(profile);

  const checkIfCanidateInHotPipeline = useAppSelector(
    candidateDetailsSelectors.getIsCandidateInHotPipeline,
  );

  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(
    !!location.state?.nextCandidateStage,
  );

  if (!candidateDetails || !checkIfCanidateInHotPipeline) {
    return <></>;
  }

  const { Hot_Pipeline_Start_Date } = candidateDetails;

  const daysLeft = Hot_Pipeline_Start_Date
    ? getDaysBetweenDates(Hot_Pipeline_Start_Date)
    : 0;

  const showDaysLeft = daysLeft < 0 || daysLeft > 30 ? null : daysLeft;
  const getTextColor = () => {
    return daysLeft <= 10 ? '#D32F2F' : 'theme.palette.text.primary';
  };

  return (
    <React.Fragment>
      <Stack
        sx={(theme) => ({
          padding: '0.5rem',
          borderRadius: '10px',
          background: theme.palette.background.brandLight,
        })}
      >
        <RowLayout
          label={'Hot Pipeline'}
          iconLabelFront={'🔥'}
          Component={
            <>
              {showDaysLeft ? (
                <Typography
                  variant="body1"
                  pr={1}
                  sx={{ color: getTextColor() }}
                >
                  {showDaysLeft} days left
                </Typography>
              ) : null}

              <Typography variant="body1" color="text.secondary">
                Added on{' '}
                {Hot_Pipeline_Start_Date &&
                  formatAndAddDaysToDate(Hot_Pipeline_Start_Date)}{' '}
              </Typography>
            </>
          }
          EndComponent={
            <CustomButton
              isDisabled={!isAbleToGenerateLink}
              size="small"
              color="secondary"
              label={'Invite for a sync'}
              startIcon={<VideoCallOutlinedIcon fontSize="small" />}
              onClick={() => setIsChangeStatusModalOpen(true)}
            />
          }
        />
      </Stack>
      <HotPipelineInviteCandidateModal
        isOpen={isChangeStatusModalOpen}
        onModalClose={() => {
          window.history.replaceState({}, '');
          setIsChangeStatusModalOpen(false);
        }}
        candidate={candidateDetails}
      />
    </React.Fragment>
  );
};
