import * as yup from 'yup';
import { useFormik } from 'formik';
import { useAppDispatch } from '@redux/hooks';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Flex } from '@components';

import { IJobOpeningCrucialRequirement } from '@types';
import { createJobOpeningCrucialRequirement } from '@redux/options';
import {
  JobOpeningCrucialRequirementPriority,
  JobOpeningCrucialRequirementCategory,
  JobOpeningCrucialRequirementCategoryLabel,
  JobOpeningCrucialRequirementCategoryOrder,
} from '@constants';

const validationSchema = yup.object({
  name: yup
    .string()
    .max(255, 'Max length is 255 symbols')
    .required('Name is required'),
  category: yup.string().required('Name is required'),
});

export const AddNewCrucialRequirementModal: React.FC<{
  isOpen: boolean;
  defaultName?: string;
  onSuccessfulCreate: (requirement: IJobOpeningCrucialRequirement) => void;
  onClose: () => void;
}> = ({ isOpen, defaultName, onSuccessfulCreate, onClose }) => {
  const dispatch = useAppDispatch();

  const formik = useFormik<{
    name: string;
    category: JobOpeningCrucialRequirementCategory;
  }>({
    initialValues: {
      name: defaultName || '',
      category: JobOpeningCrucialRequirementCategory.Technology,
    },
    validationSchema,
    onSubmit: async (values) => {
      const res = await dispatch(
        createJobOpeningCrucialRequirement({
          name: values.name!,
          category: values.category!,
        }),
      );

      if (createJobOpeningCrucialRequirement.fulfilled.match(res)) {
        onSuccessfulCreate({
          name: values.name!,
          priority: JobOpeningCrucialRequirementPriority.MustHave,
          category: values.category!,
        });
      }
    },
    enableReinitialize: true,
  });

  const nameError =
    formik.errors.name && (formik.touched.name || formik.submitCount)
      ? formik.errors.name
      : null;
  const categoryError =
    formik.errors.category && (formik.touched.category || formik.submitCount)
      ? formik.errors.category
      : null;

  return (
    <Dialog open={isOpen} scroll="body" fullWidth>
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h2">Create new crucial requirement</Typography>
          <CloseIcon
            onClick={onClose}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Flex>
      </DialogTitle>
      <DialogContent>
        <Flex flexDirection="column" gap={3} width="100%" alignItems="start">
          <Box
            sx={(theme) => ({
              background: theme.palette.background.inputDisabled,
              borderRadius: '4px',
            })}
            width="100%"
            px={2}
            py={1}
          >
            <Typography>
              This requirement will be saved for the future use in other
              positions
            </Typography>
          </Box>
          <TextField
            name="name"
            label="Requirement title"
            variant="outlined"
            fullWidth
            value={formik.values.name}
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={nameError || undefined}
            error={!!nameError}
          />
          <FormControl sx={{ marginLeft: '16px' }}>
            <FormLabel
              id="category-radio-group"
              error={!!categoryError}
              sx={{ marginBottom: '16px' }}
            >
              Category
            </FormLabel>
            <RadioGroup
              aria-labelledby="category-radio-group"
              value={formik.values.category}
              onChange={(e) => formik.setFieldValue('category', e.target.value)}
              sx={{ gap: 2 }}
            >
              {JobOpeningCrucialRequirementCategoryOrder.map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio disableRipple />}
                  label={JobOpeningCrucialRequirementCategoryLabel[option]}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Flex>
      </DialogContent>
      <DialogActions>
        <Flex gap={1} pl={2} pb={1}>
          <Button variant="contained" onClick={() => formik.handleSubmit()}>
            Save
          </Button>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Flex>
      </DialogActions>
    </Dialog>
  );
};
