import { IActionReportOptions, IEntityMainTableHeaderCell } from '@types';
import { TableHeaderCellAligment } from './table';

export enum ActionReportGroupingOptions {
  NONE = 'None',
  STAGE = 'Stage',
}

export enum ActionReportSortingOptions {
  NONE = '',
  NAME = 'Full_Name',
  LOCATION = 'Location_Country',
  INTRO_DONE = 'Date_Of_Prescreen',
  RECRUITER_RESPONSIBLE = 'Leads_Owner_2018',
  ADDED_BY = 'Added_by',
  STATUS = 'Candidate_Status',
  TOM = 'TOM',
  NH_START = 'New_Home_Date_Start',
  NH_END = 'New_Home_Date_End',
  SENIORITY = 'Seniority_Level',
  TECH_FLOW = 'Technical_Flow',
  STAGE = 'Stage',
  CANDIDATE_STATUS_UPDATED_DATE = 'Candidate_Status_Updated_At',
}

export const ActionReportFilterFields: Array<keyof IActionReportOptions> = [
  'state',
  'country',
  'seniority',
  'techFlow',
  'payRate',
  'stage',
  'recruiter',
  'addedBy',
];

export const ActionReportGroupOptions = [
  { label: 'None', value: ActionReportGroupingOptions.NONE },
  { label: 'Stage', value: ActionReportGroupingOptions.STAGE },
];

const restOfHeaders: IEntityMainTableHeaderCell[] = [
  {
    label: 'Seniority',
    value: ActionReportSortingOptions.SENIORITY,
    width: 100,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Main stack',
    value: ActionReportSortingOptions.TECH_FLOW,
    width: 260,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Added by',
    value: ActionReportSortingOptions.ADDED_BY,
    width: 213,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Changed on',
    value: ActionReportSortingOptions.CANDIDATE_STATUS_UPDATED_DATE,
    width: 213,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Status',
    value: ActionReportSortingOptions.STATUS,
    width: 209,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Action',
    value: null,
    width: 201,
    align: TableHeaderCellAligment.Left,
    isSortable: false,
  },
  {
    label: 'Score',
    value: null,
    width: 124,
    align: TableHeaderCellAligment.Left,
    isSortable: false,
  },
];

export const actionReportTableHeaders: Record<
  ActionReportGroupingOptions,
  IEntityMainTableHeaderCell[]
> = {
  [ActionReportGroupingOptions.NONE]: [
    {
      label: 'Candidate',
      value: ActionReportSortingOptions.NAME,
      width: 230,
      align: TableHeaderCellAligment.Left,
      isSortable: true,
    },
    ...restOfHeaders,
  ],
  [ActionReportGroupingOptions.STAGE]: [
    {
      label: 'Stage/Candidate',
      value: ActionReportSortingOptions.STAGE,
      width: 230,
      align: TableHeaderCellAligment.Left,
      isSortable: true,
    },
    ...restOfHeaders,
  ],
};
