import { SyntheticEvent, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { sortBy } from 'lodash';

import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import {
  fetchJobOpeningCrucialRequirements,
  optionsSelectors,
} from '@redux/options';
import { isStatusIdle } from '@utils';
import { IJobOpeningCrucialRequirement } from '@types';
import React from 'react';
import {
  JobOpeningCrucialRequirementCategory,
  JobOpeningCrucialRequirementCategoryLabel,
  JobOpeningCrucialRequirementPriority,
} from '@constants';

const ADD_OPTION = 'Add new...';

export const CrucialRequirementsSearch: React.FC<{
  selectedRequirements: string[];
  onSelect: (requirement: IJobOpeningCrucialRequirement) => void;
  onAdd: (name: string) => void;
}> = ({ selectedRequirements, onSelect, onAdd }) => {
  const dispatch = useAppDispatch();

  const { apiStatus, data } = useAppSelector(
    optionsSelectors.getJobOpeningCrucialRequirements,
  );

  useEffect(() => {
    if (isStatusIdle(apiStatus)) {
      dispatch(fetchJobOpeningCrucialRequirements());
    }
  }, [apiStatus]);

  const options = useMemo(
    () =>
      sortBy(
        data.filter(
          (requirement) => !selectedRequirements.includes(requirement.name),
        ),
        'category',
      ),
    [data, selectedRequirements],
  );

  return (
    <Autocomplete
      value={null}
      fullWidth
      options={options}
      groupBy={(option) => option.category}
      getOptionLabel={(option) => option.name}
      renderGroup={(params: AutocompleteRenderGroupParams) => (
        <li key={params.key}>
          <Typography variant="body2" color="text.secondary" pl={2} my={2}>
            {
              JobOpeningCrucialRequirementCategoryLabel[
                params.group as JobOpeningCrucialRequirementCategory
              ]
            }
          </Typography>
          <ul style={{ paddingLeft: 0 }}>{params.children}</ul>
        </li>
      )}
      filterOptions={(options, params) => {
        const filtered = createFilterOptions<IJobOpeningCrucialRequirement>()(
          options,
          params,
        );

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            name: `Add "${inputValue}"`,
            priority: JobOpeningCrucialRequirementPriority.MustHave,
            category: JobOpeningCrucialRequirementCategory.Technology,
          });
        }

        filtered.push({
          name: ADD_OPTION,
        } as IJobOpeningCrucialRequirement);

        return filtered;
      }}
      onChange={(
        e: SyntheticEvent,
        newValue: IJobOpeningCrucialRequirement | null,
      ) => {
        if (!newValue) return;

        const isAdd =
          newValue.name?.includes('Add "') || newValue.name === ADD_OPTION;

        if (isAdd) {
          const newReqNameValue = /Add "(.*)"/gi.exec(newValue.name)?.[1] || '';
          onAdd(newReqNameValue);
        } else {
          onSelect({
            name: newValue.name,
            priority: JobOpeningCrucialRequirementPriority.MustHave,
            category: newValue.category,
          });
        }
      }}
      blurOnSelect
      clearOnBlur
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Add requirement"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        const isAddOption = option.name === ADD_OPTION;
        return (
          <li {...props}>
            <Typography color={isAddOption ? 'text.link' : 'text.primary'}>
              {option.name}
            </Typography>
          </li>
        );
      }}
    />
  );
};
