import React from 'react';
import { Box, Fade } from '@mui/material';
import { Loader } from 'components';
import { fadeInDuration } from 'theme';

interface IProps {
  children: React.ReactNode;
  isLoading?: boolean;
}

export const PageWrapper: React.FC<IProps> = ({
  children,
  isLoading = false,
}) => {
  return (
    <>
      {isLoading && <Loader />}
      <Fade in={!isLoading} timeout={fadeInDuration} mountOnEnter>
        <Box sx={{ height: '100%' }}>{children}</Box>
      </Fade>
    </>
  );
};
