import { flatten, uniq } from 'lodash';

export enum CandidateStatus {
  Interested = 'LP: Interested',
  Applied = 'LP: Applied',
  Selfgen = 'LP: Self-gen',

  VI_Sent = 'LP: VI sent',
  VI_Done = 'LP: VI done',
  VI_Passed = 'LP: VI passed',
  VI_Bad_Soft_Skills = 'LP: VI failed: Soft skills',
  VI_Wrong_Answers = 'LP: VI failed: Wrong answers',
  VI_Incomplete = 'LP: VI failed: Incomplete',
  VI_Low_English = 'LP: No IC: VI - low English',

  TT_Sent = 'LP: TT sent',
  TT_Done = 'LP: TT done',
  TT_Passed = 'LP: TT passed',
  TT_Failed = 'LP: TT failed',
  TT_Refused = 'LP: Refused to complete TT',

  IC_Waitlist = 'LP: IC waitlist',
  IC_Scheduled = 'LP: IC scheduled',
  IC_Done = 'LP: IC done',
  IC_Passed = 'LP: IC passed',
  IC_Rejected_By_Recruiter_PositionClosed = 'LP: IC rejected by Recruiter: Position is closed',
  IC_Rejected_By_Recruiter_Location = 'LP: IC rejected by Recruiter: Location',
  IC_Rejected_By_Recruiter_SkillSet = 'LP: IC rejected by Recruiter: Skill set',
  IC_Rejected_By_Recruiter_Capacity = 'LP: IC rejected by Recruiter: Capacity',
  IC_Canceled_By_Candidate = 'LP: IC canceled by Candidate',
  IC_Canceled_By_Recruiter = 'LP: IC canceled by Recruiter',
  IC_No_Show = 'LP: IC no-show',

  Setting_TI = 'LP: Setting up TI',
  TI_Scheduled = 'LP: TI scheduled',
  TI_Done = 'LP: TI done',
  TI_Passed = 'LP: TI passed',
  TI_Passed_Lower_Seniority = 'LP: TI passed at a lower seniority',
  TI_Failed_For_AE = 'LP: TI failed for AgileEngine',
  TI_Failed_For_Project = 'LP: TI failed for this project',

  Setting_TL_Intro = 'LP: Setting up TL intro',
  TL_Intro_Scheduled = 'LP: TL intro scheduled',
  TL_Intro_Done = 'LP: TL intro done',
  TL_Intro_Passed = 'LP: TL intro passed',
  TL_Intro_Failed_Soft_Skills = 'LP: TL intro failed: Soft skills',
  TL_Intro_Failed_Tech_Skills = 'LP: TL intro failed: Tech skills',
  TL_Intro_Failed_Teach_Soft_Skills = 'LP: TL intro failed: Tech and soft skills',

  Preparing_Submission = 'LP: Preparing for submission',
  Submitted = 'LP: Submitted',
  Submission_Rejected_Location = 'LP: Submission rejected: Location',
  Submission_Rejected_Seniority = 'LP: Submission rejected: Seniority',
  Submission_Rejected_Tech_Skills = 'LP: Submission rejected: Tech skills',

  CI_Scheduled = 'LP: CI scheduled',
  CI_Done = 'LP: CI done',
  CI_Passed = 'LP: CI passed',
  CI_Failed_Communication = 'LP: CI failed: Communication skills',
  CI_Failed_Location = 'LP: CI failed: Location',
  CI_Failed_Seniority = 'LP: CI failed: Seniority',
  CI_Failed_Tech_Skills = 'LP: CI failed: Tech skills',

  SOW_Signing = 'LP: Signing SOW',
  SOW_Signed = 'LP: SOW signed',
  SOW_Rejected = 'LP: SOW rejected',

  Offer_Sent = 'LP: Offer sent',
  Hired = 'LP: Hired',
  Hired_NewHome = 'LP: Hired from New Home',
  Offer_Rejected = 'LP: Offer rejected',

  Accepted_Another_Offer_Compensation = 'LP: Accepted another offer: Compensation',
  Accepted_Another_Offer_Project = 'LP: Accepted another offer: Project',
  Accepted_Another_Offer_Retained = 'LP: Accepted another offer: Retained',
  Accepted_Another_Offer_Role = 'LP: Accepted another offer: Role',
  Accepted_Another_Offer_TimeRelated = 'LP: Accepted another offer: Time-related',
  Accepted_Another_Offer_UnclearReason = 'LP: Accepted another offer: Unclear reason',

  Disqualified_LeagalIssues = 'LP: Disqualified: Accounting/Legal issues',
  Disqualified_CheatedVI = 'LP: Disqualified: Cheated on VI',
  Disqualified_CheatedTT = 'LP: Disqualified: Cheated on Test Task',
  Disqualified_CheatedIC = 'LP: DisQualified: Cheated on IC/Fake profile',
  Disqualified_CLevel = 'LP: Disqualified: C-level',
  Disqualified_DidntStartWorking = "LP: Disqualified: Didn't start working",
  Disqualified_FailedBackgroundCheck = 'LP: Disqualified: Failed background check',
  Disqualified_LowEnglishLEvel = 'LP: Disqualified: Low English level',
  Disqualified_NotFitLocation = 'LP: Disqualified: Not a fit by location',
  Disqualified_NotFitSoftSkills = 'LP: Disqualified: Not a fit by soft skills',
  Disqualified_RejectedOffer = 'LP: Disqualified: Rejected offer after signing',
  Disqualified_RelocationOnly = 'LP: Disqualified: Relocation only',

  No_Suitable_Openings_Seniority = 'LP: No suitable openings: Seniority',
  No_Suitable_Openings_CoreStack = 'LP: No suitable openings: Core stack',
  No_Suitable_Openings_Location = 'LP: No suitable openings: Location',
  No_Suitable_Openings_NotOurCoreStack = 'LP: No suitable openings: Not our core stack',
  No_Suitable_Openings_PartTimeOnly = 'LP: No suitable openings: Part-time only',
  No_Suitable_After_TI_Passed = 'LP: No suitable openings: After TI Passed',
  No_Suitable_Openings_After_HotPipeline = 'LP: No suitable openings: after Hot Pipeline',
  No_Suitable_Openings_CrucialRequirements = 'LP: No suitable openings: Crucial requirements',

  AllOptionsRefused = 'LP: All options were refused by candidate',
  AnotherPersonHired = 'LP: Another person was hired',
  HighSalaryExpectations = 'LP: High salary expectations',
  HiredForAnotherProject = 'LP: Hired for another project',
  PositionCancelled = 'LP: Position cancelled/on-hold',
  RefusedContinue = 'LP: Refused to continue process',
  StoppedReplying = 'LP: Stopped replying',

  ClientStoppedReplying = 'LP: Client stopped replying',

  In_Hot_Pipeline = 'LP: Candidate in Hot Pipeline',

  OnHold = 'LP: On-hold',
}

export enum CandidateStatusStage {
  Application = 'Application',
  VideoInterview = 'Video Interview',
  TestTask = 'Test Task',
  IC = 'Intro Call',
  TI = 'Technical Interview',
  TLIntro = 'TL Intro',
  Submission = 'Submission',
  CI = 'Client Interview',
  SOW = 'SOW',
  Hiring = 'Hiring',
}

export const HiddenCandidateStatuses: CandidateStatus[] = [
  CandidateStatus.IC_Waitlist,
  CandidateStatus.No_Suitable_Openings_CrucialRequirements,
];

export const AcceptedAnotherOfferStatuses: CandidateStatus[] = [
  CandidateStatus.Accepted_Another_Offer_Compensation,
  CandidateStatus.Accepted_Another_Offer_Project,
  CandidateStatus.Accepted_Another_Offer_Retained,
  CandidateStatus.Accepted_Another_Offer_Role,
  CandidateStatus.Accepted_Another_Offer_TimeRelated,
  CandidateStatus.Accepted_Another_Offer_UnclearReason,
];

export const DisqualifiedStatuses: CandidateStatus[] = [
  CandidateStatus.Disqualified_LeagalIssues,
  CandidateStatus.Disqualified_CheatedTT,
  CandidateStatus.Disqualified_CheatedVI,
  CandidateStatus.Disqualified_CheatedIC,
  CandidateStatus.Disqualified_CLevel,
  CandidateStatus.Disqualified_DidntStartWorking,
  CandidateStatus.Disqualified_FailedBackgroundCheck,
  CandidateStatus.Disqualified_LowEnglishLEvel,
  CandidateStatus.Disqualified_NotFitLocation,
  CandidateStatus.Disqualified_NotFitSoftSkills,
  CandidateStatus.Disqualified_RejectedOffer,
  CandidateStatus.Disqualified_RelocationOnly,
];

export const NoSuitableOpeningsStatuses: CandidateStatus[] = [
  CandidateStatus.No_Suitable_Openings_Seniority,
  CandidateStatus.No_Suitable_Openings_CoreStack,
  CandidateStatus.No_Suitable_Openings_Location,
  CandidateStatus.No_Suitable_Openings_NotOurCoreStack,
  CandidateStatus.No_Suitable_Openings_PartTimeOnly,
  CandidateStatus.No_Suitable_After_TI_Passed,
  CandidateStatus.No_Suitable_Openings_After_HotPipeline,
  CandidateStatus.No_Suitable_Openings_CrucialRequirements,
];

export const NonStageRelatedStatuses: CandidateStatus[] = [
  CandidateStatus.AllOptionsRefused,
  CandidateStatus.AnotherPersonHired,
  CandidateStatus.HighSalaryExpectations,
  CandidateStatus.HiredForAnotherProject,
  CandidateStatus.PositionCancelled,
  CandidateStatus.RefusedContinue,
  CandidateStatus.StoppedReplying,
  CandidateStatus.OnHold,
  CandidateStatus.In_Hot_Pipeline,
];

export const ClosingStatusesByStage: Record<
  CandidateStatusStage,
  CandidateStatus[]
> = {
  [CandidateStatusStage.Application]: [],
  [CandidateStatusStage.VideoInterview]: [
    CandidateStatus.VI_Bad_Soft_Skills,
    CandidateStatus.VI_Wrong_Answers,
    CandidateStatus.VI_Incomplete,
    CandidateStatus.VI_Low_English,
  ],
  [CandidateStatusStage.TestTask]: [
    CandidateStatus.TT_Failed,
    CandidateStatus.TT_Refused,
  ],
  [CandidateStatusStage.IC]: [
    CandidateStatus.IC_Rejected_By_Recruiter_PositionClosed,
    CandidateStatus.IC_Rejected_By_Recruiter_Location,
    CandidateStatus.IC_Rejected_By_Recruiter_SkillSet,
    CandidateStatus.IC_Rejected_By_Recruiter_Capacity,
    CandidateStatus.IC_Canceled_By_Candidate,
    CandidateStatus.IC_Canceled_By_Recruiter,
    CandidateStatus.IC_No_Show,
  ],
  [CandidateStatusStage.TI]: [
    CandidateStatus.TI_Failed_For_AE,
    CandidateStatus.TI_Failed_For_Project,
  ],
  [CandidateStatusStage.TLIntro]: [
    CandidateStatus.TL_Intro_Failed_Soft_Skills,
    CandidateStatus.TL_Intro_Failed_Tech_Skills,
    CandidateStatus.TL_Intro_Failed_Teach_Soft_Skills,
  ],
  [CandidateStatusStage.Submission]: [
    CandidateStatus.ClientStoppedReplying,
    CandidateStatus.Submission_Rejected_Location,
    CandidateStatus.Submission_Rejected_Seniority,
    CandidateStatus.Submission_Rejected_Tech_Skills,
  ],
  [CandidateStatusStage.CI]: [
    CandidateStatus.ClientStoppedReplying,
    CandidateStatus.CI_Failed_Communication,
    CandidateStatus.CI_Failed_Location,
    CandidateStatus.CI_Failed_Seniority,
    CandidateStatus.CI_Failed_Tech_Skills,
  ],
  [CandidateStatusStage.SOW]: [CandidateStatus.SOW_Rejected],
  [CandidateStatusStage.Hiring]: [CandidateStatus.Offer_Rejected],
};

export const PassedStatusesByStage: Record<
  CandidateStatusStage,
  CandidateStatus[]
> = {
  [CandidateStatusStage.Application]: [
    CandidateStatus.Applied,
    CandidateStatus.Selfgen,
  ],
  [CandidateStatusStage.VideoInterview]: [CandidateStatus.VI_Passed],
  [CandidateStatusStage.TestTask]: [CandidateStatus.TT_Passed],
  [CandidateStatusStage.IC]: [CandidateStatus.IC_Passed],
  [CandidateStatusStage.TI]: [
    CandidateStatus.TI_Passed,
    CandidateStatus.TI_Passed_Lower_Seniority,
  ],
  [CandidateStatusStage.TLIntro]: [CandidateStatus.TL_Intro_Passed],
  [CandidateStatusStage.Submission]: [],
  [CandidateStatusStage.CI]: [CandidateStatus.CI_Passed],
  [CandidateStatusStage.SOW]: [CandidateStatus.SOW_Signed],
  [CandidateStatusStage.Hiring]: [
    CandidateStatus.Hired,
    CandidateStatus.Hired_NewHome,
  ],
};

export const ProcessStatusesByStage: Record<
  CandidateStatusStage,
  CandidateStatus[]
> = {
  [CandidateStatusStage.Application]: [CandidateStatus.Interested],
  [CandidateStatusStage.VideoInterview]: [
    CandidateStatus.VI_Sent,
    CandidateStatus.VI_Done,
  ],
  [CandidateStatusStage.TestTask]: [
    CandidateStatus.TT_Sent,
    CandidateStatus.TT_Done,
  ],
  [CandidateStatusStage.IC]: [
    CandidateStatus.IC_Waitlist,
    CandidateStatus.IC_Scheduled,
    CandidateStatus.IC_Done,
  ],
  [CandidateStatusStage.TI]: [
    CandidateStatus.Setting_TI,
    CandidateStatus.TI_Scheduled,
    CandidateStatus.TI_Done,
  ],
  [CandidateStatusStage.TLIntro]: [
    CandidateStatus.Setting_TL_Intro,
    CandidateStatus.TL_Intro_Scheduled,
    CandidateStatus.TL_Intro_Done,
  ],
  [CandidateStatusStage.Submission]: [
    CandidateStatus.Preparing_Submission,
    CandidateStatus.Submitted,
  ],
  [CandidateStatusStage.CI]: [
    CandidateStatus.CI_Scheduled,
    CandidateStatus.CI_Done,
  ],
  [CandidateStatusStage.SOW]: [CandidateStatus.SOW_Signing],
  [CandidateStatusStage.Hiring]: [CandidateStatus.Offer_Sent],
};

export const ClosingStatusesWithoutFields: CandidateStatus[] = [
  CandidateStatus.StoppedReplying,
  CandidateStatus.AnotherPersonHired,
  CandidateStatus.TT_Refused,
];

export const CandidateStatusesByStage: Record<
  CandidateStatusStage,
  CandidateStatus[]
> = Object.values(CandidateStatusStage).reduce(
  (acc, stage) => ({
    ...acc,
    [stage]: ([] as CandidateStatus[])
      .concat(ProcessStatusesByStage[stage])
      .concat(PassedStatusesByStage[stage])
      .concat(ClosingStatusesByStage[stage])
      .concat(AcceptedAnotherOfferStatuses)
      .concat(DisqualifiedStatuses)
      .concat(NoSuitableOpeningsStatuses)
      .concat(NonStageRelatedStatuses),
  }),
  {} as Record<CandidateStatusStage, CandidateStatus[]>,
);

export const AllClosedStatuses = uniq(
  flatten(Object.values(ClosingStatusesByStage))
    .concat(AcceptedAnotherOfferStatuses)
    .concat(DisqualifiedStatuses)
    .concat(NoSuitableOpeningsStatuses)
    .concat(NonStageRelatedStatuses),
);

export const EngineeringCandidateStagesOrder: CandidateStatusStage[] = [
  CandidateStatusStage.Application,
  CandidateStatusStage.VideoInterview,
  CandidateStatusStage.TestTask,
  CandidateStatusStage.IC,
  CandidateStatusStage.TI,
  CandidateStatusStage.TLIntro,
  CandidateStatusStage.Submission,
  CandidateStatusStage.CI,
  CandidateStatusStage.SOW,
  CandidateStatusStage.Hiring,
];

export const NonEngineeringCandidateStagesOrder: CandidateStatusStage[] = [
  CandidateStatusStage.Application,
  CandidateStatusStage.VideoInterview,
  CandidateStatusStage.IC,
  CandidateStatusStage.TestTask,
  CandidateStatusStage.TI,
  CandidateStatusStage.Submission,
  CandidateStatusStage.CI,
  CandidateStatusStage.Hiring,
];

export const ExcludedStatusesByStatus: Partial<
  Record<CandidateStatus, CandidateStatus[]>
> = {
  [CandidateStatus.TT_Refused]: [CandidateStatus.TT_Done],
  [CandidateStatus.IC_Canceled_By_Candidate]: [CandidateStatus.IC_Done],
  [CandidateStatus.IC_Canceled_By_Recruiter]: [CandidateStatus.IC_Done],
  [CandidateStatus.IC_Rejected_By_Recruiter_PositionClosed]: [
    CandidateStatus.IC_Done,
  ],
  [CandidateStatus.IC_Rejected_By_Recruiter_Location]: [
    CandidateStatus.IC_Done,
  ],
  [CandidateStatus.IC_Rejected_By_Recruiter_SkillSet]: [
    CandidateStatus.IC_Done,
  ],
  [CandidateStatus.IC_Rejected_By_Recruiter_Capacity]: [
    CandidateStatus.IC_Done,
  ],
  [CandidateStatus.IC_No_Show]: [CandidateStatus.IC_Done],
};

export const ExcludedTransitionStatuses: CandidateStatus[] = [
  CandidateStatus.IC_Waitlist,
];

export const LeadClosedBecauseByCandidateStatus: Partial<
  Record<CandidateStatus, string>
> = {
  [CandidateStatus.VI_Bad_Soft_Skills]: 'Video Interview failed',
  [CandidateStatus.VI_Wrong_Answers]: 'Video Interview failed',
  [CandidateStatus.VI_Incomplete]: 'Video Interview failed',
  [CandidateStatus.VI_Low_English]: 'No IC: VI - low English',

  [CandidateStatus.TT_Failed]: 'Test Task failed',
  [CandidateStatus.TT_Refused]: 'Refused to complete Test Task',

  [CandidateStatus.IC_Rejected_By_Recruiter_PositionClosed]:
    'Rejected by recruiter Position closed',
  [CandidateStatus.IC_Rejected_By_Recruiter_Capacity]:
    'Rejected by recruiter No capacity',
  [CandidateStatus.IC_Rejected_By_Recruiter_Location]:
    'Rejected by recruiter Location',
  [CandidateStatus.IC_Rejected_By_Recruiter_SkillSet]:
    'Rejected by recruiter Skill set',
  [CandidateStatus.IC_Canceled_By_Candidate]: 'Intro call no-show',
  [CandidateStatus.IC_No_Show]: 'Intro call no-show',

  [CandidateStatus.TI_Failed_For_AE]: 'Tech Interview failed',
  [CandidateStatus.TI_Failed_For_Project]: 'Tech Interview failed',

  [CandidateStatus.TL_Intro_Failed_Soft_Skills]: 'Rejected by TL',
  [CandidateStatus.TL_Intro_Failed_Tech_Skills]: 'Rejected by TL',
  [CandidateStatus.TL_Intro_Failed_Teach_Soft_Skills]: 'Rejected by TL',

  [CandidateStatus.Submission_Rejected_Location]: 'Rejected in submission',
  [CandidateStatus.Submission_Rejected_Seniority]: 'Rejected in submission',
  [CandidateStatus.Submission_Rejected_Tech_Skills]: 'Rejected in submission',

  [CandidateStatus.CI_Failed_Communication]: 'Client Interview failed',
  [CandidateStatus.CI_Failed_Location]: 'Client Interview failed',
  [CandidateStatus.CI_Failed_Seniority]: 'Client Interview failed',
  [CandidateStatus.CI_Failed_Tech_Skills]: 'Client Interview failed',

  [CandidateStatus.SOW_Rejected]: 'SOW rejected',

  [CandidateStatus.Offer_Rejected]:
    'DisQualified: rejected offer after acceptance',

  [CandidateStatus.Accepted_Another_Offer_Compensation]:
    'Accepted another offer: compensation',
  [CandidateStatus.Accepted_Another_Offer_Project]:
    'Accepted another offer: project',
  [CandidateStatus.Accepted_Another_Offer_Retained]:
    'Accepted another offer: retained',
  [CandidateStatus.Accepted_Another_Offer_Role]: 'Accepted another offer: role',
  [CandidateStatus.Accepted_Another_Offer_TimeRelated]:
    'Accepted another offer: time-related',
  [CandidateStatus.Accepted_Another_Offer_UnclearReason]:
    'Accepted another offer: unclear reason',

  [CandidateStatus.Disqualified_LeagalIssues]:
    'DisQualified: Legal restrictions',
  [CandidateStatus.Disqualified_CheatedVI]:
    'Disqualified: Cheated on Video Interview',
  [CandidateStatus.Disqualified_CheatedTT]:
    'Disqualified: Cheated on Test Task',
  [CandidateStatus.Disqualified_CheatedIC]:
    'DisQualified: Cheated on IC/Fake profile',
  [CandidateStatus.Disqualified_CLevel]: 'DisQualified: C-level',
  [CandidateStatus.Disqualified_DidntStartWorking]:
    'DisQualified: hasn’t started working after offer acceptance',
  [CandidateStatus.Disqualified_FailedBackgroundCheck]:
    'Disqualified: Failed background check',
  [CandidateStatus.Disqualified_LowEnglishLEvel]:
    'DisQualified: Low level of English',
  [CandidateStatus.Disqualified_NotFitLocation]:
    'DisQualified: Not a fit by location',
  [CandidateStatus.Disqualified_NotFitSoftSkills]: 'Not a fit by soft skills',
  [CandidateStatus.Disqualified_RejectedOffer]:
    'DisQualified: rejected offer after acceptance',
  [CandidateStatus.Disqualified_RelocationOnly]:
    'DisQualified: Relocation only',

  [CandidateStatus.No_Suitable_Openings_CoreStack]:
    'No suitable openings: Core stack',
  [CandidateStatus.No_Suitable_Openings_Location]:
    'No suitable openings: location',
  [CandidateStatus.No_Suitable_Openings_NotOurCoreStack]:
    'No suitable openings: Not our core stack',
  [CandidateStatus.No_Suitable_Openings_PartTimeOnly]:
    'DisQualified: Part-time load only',
  [CandidateStatus.No_Suitable_Openings_Seniority]:
    'No suitable openings: seniority',
  [CandidateStatus.No_Suitable_After_TI_Passed]:
    'No suitable openings: After TI Passed',
  [CandidateStatus.No_Suitable_Openings_After_HotPipeline]:
    'No suitable openings: after Hot Pipeline',
  [CandidateStatus.No_Suitable_Openings_CrucialRequirements]:
    'No suitable openings: Crucial requirements',

  [CandidateStatus.AllOptionsRefused]: 'All options were refused by candidate',
  [CandidateStatus.AnotherPersonHired]: 'Another person was hired',
  [CandidateStatus.HighSalaryExpectations]: 'High salary expectations',
  [CandidateStatus.HiredForAnotherProject]: 'Another person was hired',
  [CandidateStatus.PositionCancelled]: 'Position cancelled/on-hold',
  [CandidateStatus.RefusedContinue]: 'Refused to continue process',
  [CandidateStatus.StoppedReplying]: 'Stopped replying',

  [CandidateStatus.ClientStoppedReplying]: 'Not now',
};
