import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from '@constants';
import { IOptionsSliceState } from './options.types';
import { optionsStoreKey } from './options.const';
import {
  fetchCandidateAddedByOptions,
  fetchCandidateFieldsOptions,
  fetchCandidateResponsibleRecruiters,
  fetchClientsForSubmission,
  fetchClosedJobOpeningsOptions,
  fetchEmployees,
  fetchJobOpeningClients,
  fetchJobOpeningCountries,
  fetchJobOpeningFieldsOptions,
  fetchJobOpeningSpecializations,
  fetchJobOpeningSubteams,
  fetchJobOpeningsOptions,
  fetchLPTestTasks,
  fetchLaunchpodSpecializations,
  fetchJobOpeningCrucialRequirements,
  createJobOpeningCrucialRequirement,
} from './options.thunks';

const DataActionsMap: {
  action: any;
  stateSlice: keyof IOptionsSliceState;
}[] = [
  { action: fetchJobOpeningsOptions, stateSlice: 'jobOpenings' },
  { action: fetchClosedJobOpeningsOptions, stateSlice: 'closedJobOpenings' },
  { action: fetchJobOpeningFieldsOptions, stateSlice: 'jobOpeningFields' },
  {
    action: fetchJobOpeningCountries,
    stateSlice: 'jobOpeningCountries',
  },
  {
    action: fetchJobOpeningSpecializations,
    stateSlice: 'jobOpeningSpecializations',
  },
  { action: fetchJobOpeningClients, stateSlice: 'jobOpeningClients' },
  { action: fetchJobOpeningSubteams, stateSlice: 'jobOpeningSubteams' },
  {
    action: fetchJobOpeningCrucialRequirements,
    stateSlice: 'jobOpeningCrucialRequirements',
  },
  { action: fetchCandidateFieldsOptions, stateSlice: 'candidateFields' },
  {
    action: fetchCandidateResponsibleRecruiters,
    stateSlice: 'candidateResponsibleRecruiters',
  },
  { action: fetchCandidateAddedByOptions, stateSlice: 'candidateAddedBy' },
  { action: fetchLPTestTasks, stateSlice: 'lpTestTasks' },
  { action: fetchClientsForSubmission, stateSlice: 'submissionClients' },
  { action: fetchLaunchpodSpecializations, stateSlice: 'lpSpecializations' },
];

const initialState: IOptionsSliceState = {
  jobOpenings: {
    apiStatus: ApiStatus.IDLE,
    data: [],
  },
  closedJobOpenings: {
    apiStatus: ApiStatus.IDLE,
    data: [],
  },
  jobOpeningFields: {
    apiStatus: ApiStatus.IDLE,
    data: null,
    requestedFetch: false,
  },
  jobOpeningCountries: {
    data: [],
    apiStatus: ApiStatus.IDLE,
  },
  jobOpeningSpecializations: {
    data: [],
    apiStatus: ApiStatus.IDLE,
  },
  jobOpeningClients: {
    data: [],
    apiStatus: ApiStatus.IDLE,
  },
  jobOpeningSubteams: {
    data: [],
    apiStatus: ApiStatus.IDLE,
  },
  jobOpeningCrucialRequirements: {
    data: [],
    apiStatus: ApiStatus.IDLE,
  },
  candidateFields: {
    apiStatus: ApiStatus.IDLE,
    data: null,
    requestedFetch: false,
  },
  candidateResponsibleRecruiters: {
    apiStatus: ApiStatus.IDLE,
    data: [],
  },
  candidateAddedBy: {
    apiStatus: ApiStatus.IDLE,
    data: [],
  },
  lpTestTasks: {
    apiStatus: ApiStatus.IDLE,
    data: { codilityTestTasks: [], quizTestTasks: [], internalTestTasks: [] },
  },
  submissionClients: {
    apiStatus: ApiStatus.IDLE,
    data: [],
  },
  lpSpecializations: {
    apiStatus: ApiStatus.IDLE,
    data: null,
  },
  employees: {
    apiStatus: ApiStatus.IDLE,
    data: [],
    search: null,
  },
};

export const optionsSlice = createSlice({
  name: optionsStoreKey,
  initialState,
  reducers: {
    cleanupOptionsData: (
      state,
      action: PayloadAction<keyof IOptionsSliceState>,
    ) => {
      state[action.payload] = initialState[action.payload] as any;
    },
    requestCandidateFieldsFetch: (state) => {
      state.candidateFields.requestedFetch = true;
    },
    requestJobOpeningFieldsFetch: (state) => {
      state.jobOpeningFields.requestedFetch = true;
    },
    resetEmployeeOptions: (state) => {
      state.employees = initialState.employees;
    },
  },
  extraReducers: (builder) => {
    DataActionsMap.forEach((item) => {
      builder
        .addCase(item.action.pending, (state) => {
          state[item.stateSlice].apiStatus = ApiStatus.LOADING;
        })
        .addCase(item.action.fulfilled, (state, action) => {
          state[item.stateSlice].apiStatus = ApiStatus.COMPLETE;
          state[item.stateSlice].data = action.payload;
        })
        .addCase(item.action.rejected, (state) => {
          state[item.stateSlice].apiStatus = ApiStatus.FAILED;
        });
    });
    builder
      .addCase(fetchEmployees.pending, (state, action) => {
        state.employees.apiStatus = ApiStatus.LOADING;
        state.employees.search = action.meta.arg;
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        if (state.employees.search == action.meta.arg) {
          state.employees.apiStatus = ApiStatus.COMPLETE;
          state.employees.data = action.payload;
        }
      })
      .addCase(fetchEmployees.rejected, (state, action) => {
        if (state.employees.search == action.meta.arg) {
          state.employees.apiStatus = ApiStatus.FAILED;
        }
      })
      .addCase(
        createJobOpeningCrucialRequirement.fulfilled,
        (state, action) => {
          state.jobOpeningCrucialRequirements.data = action.payload;
        },
      );
  },
});

export const {
  requestCandidateFieldsFetch,
  requestJobOpeningFieldsFetch,
  cleanupOptionsData,
  resetEmployeeOptions,
} = optionsSlice.actions;
export const optionsSliceReducer = optionsSlice.reducer;
