import { Stack, Typography } from '@mui/material';
import { IZohoCandidate } from '@types';
import { getCandidateName } from '@utils';

export const SubmissionCandidateNameHeader: React.FC<{
  candidateDetails: IZohoCandidate | null;
}> = ({ candidateDetails }) => {
  return (
    <Stack>
      <Typography
        variant="h2"
        color="text.primary"
        noWrap
        sx={{
          fontSize: {
            xs: '1.5rem',
            md: '1.125rem',
          },
          lineHeight: '120%',
          mb: 1,
        }}
      >
        Submit candidate
        <Typography variant="body2" color={'secondary'} fontSize={'0.6rem'}>
          {getCandidateName({
            fullName: candidateDetails?.Full_Name,
            firstName: candidateDetails?.First_Name,
            lastName: candidateDetails?.Last_Name,
            englishFirstName: candidateDetails?.English_First_Name,
            englishLastName: candidateDetails?.English_Last_Name,
          })}
          , {candidateDetails?.Seniority_Level}{' '}
          {candidateDetails?.Technical_Flow}
        </Typography>
      </Typography>
    </Stack>
  );
};
