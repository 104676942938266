import React, { useState } from 'react';
import { format } from 'date-fns';
import { isNil, isNumber } from 'lodash';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Chip,
  Hidden,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RowLayout,
  CustomButton,
  JobOpeningLink,
} from '@components';
import { ClientFeeback } from './ClientFeedback';
import { SubmissionStatusChangeModal } from './SubmissionStatusChangeModal';

import { ICandidateSubmission } from '@types';

import { isStandaloneRecepient, TestTaskColor } from '@utils';
import {
  AppRoutes,
  TestTaskStatus,
  SubmissionStatus,
  apiEndpoints,
} from '@constants';

const getStatusColor = (status: SubmissionStatus) => {
  switch (status) {
    case SubmissionStatus.Draft:
      return 'text.link';
    case SubmissionStatus.Submitted:
    case SubmissionStatus.CallRequested:
      return 'text.primary';
    case SubmissionStatus.CallCancelled:
    case SubmissionStatus.Rejected:
      return 'text.danger';
    case SubmissionStatus.Hired:
      return 'text.success';
    default:
      return 'text.primary';
  }
};

export const SubmissionItem: React.FC<{
  submission: ICandidateSubmission;
  selectedSubmissionId: string | null;
  handleSubmissionChange: (newValue: string) => void;
}> = ({ submission, selectedSubmissionId, handleSubmissionChange }) => {
  const navigate = useNavigate();

  const [nextSubmissionStatus, setNextSubmissionStatus] =
    useState<SubmissionStatus | null>(null);

  const handleAccordionClick =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      handleSubmissionChange(newExpanded ? panel : '');
    };
  const onEditSubmission = () =>
    navigate(`/${AppRoutes.SUBMISSIONS}/${selectedSubmissionId}`);

  if (!submission) return null;

  const submissionDate = submission.submittedDate
    ? format(new Date(submission.submittedDate), 'yyyy-MM-dd')
    : '-';

  return (
    <React.Fragment>
      <Accordion
        key={submission.id}
        expanded={selectedSubmissionId === submission.id}
        onChange={handleAccordionClick(submission.id)}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id={submission.id}
          sx={{
            '& .MuiAccordionSummary-content': {
              gap: {
                xs: 0,
                sm: '0.5rem',
              },
              alignItems: {
                xs: 'flex-start',
                sm: 'center',
              },
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
              justifyContent: 'flex-start',
            },
          }}
        >
          <Typography variant="body1" flex={'1 1 auto'}>
            {submission.positions?.map((position) => (
              <React.Fragment key={position.id}>
                <Box component={'span'} color={'text.secondary'}>
                  #{position?.jobId}
                </Box>{' '}
                {position?.client}: {position?.name}
              </React.Fragment>
            ))}
          </Typography>
          <Hidden smDown>
            <Typography
              variant="body1"
              flex={'1 1 auto'}
              sx={{ textWrap: 'nowrap' }}
            >
              {submission.recipients?.[0]?.name}
            </Typography>
            <Typography
              variant="body1"
              color={getStatusColor(submission.status)}
              sx={{ textWrap: 'nowrap' }}
            >
              {submission.status}
            </Typography>
            <Typography
              variant="body2"
              color={'text.secondary'}
              sx={{ textWrap: 'nowrap' }}
            >
              {submissionDate}
            </Typography>
          </Hidden>
          <Hidden smUp>
            <Typography variant="body2" color={'text.secondary'}>
              {submissionDate}
              {!!submission.recipients?.[0]?.name && ' ∙ '}
              {submission.recipients?.[0]?.name}
              {' ∙ '}
              <Box component={'span'} color={getStatusColor(submission.status)}>
                {submission.status}
              </Box>
            </Typography>
          </Hidden>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction={'column'} gap={'1rem'}>
            <Stack
              sx={{
                backgroundColor: 'highlight.actionable',
                padding: '0.75rem',
                mx: {
                  xs: '-1rem',
                  sm: '-0.5rem',
                },
                mt: {
                  xs: '-1rem',
                  sm: '-0.5rem',
                },
              }}
            >
              <Stack>
                <RowLayout
                  label={'Position(s)'}
                  isEmpty={!submission.positions?.length}
                  Component={
                    <Box display="flex" flexDirection="column">
                      {submission.positions?.map((position) => (
                        <Typography variant="body1" key={position.id}>
                          <JobOpeningLink
                            jobOpeningId={position?.jobId || ''}
                            jobId={position?.id || ''}
                          />{' '}
                          {position?.name}
                        </Typography>
                      ))}
                    </Box>
                  }
                />
                <RowLayout
                  label={'Client'}
                  isEmpty={!submission.positions?.[0]?.client}
                  Component={
                    <Typography variant="body1">
                      {submission.positions?.[0]?.client}
                    </Typography>
                  }
                />
                <RowLayout
                  label={'Sent to'}
                  isEmpty={!submission.recipients?.length}
                  Component={
                    <React.Fragment>
                      <Box display={'flex'} flexWrap={'wrap'} gap={1}>
                        {submission.recipients?.map((recipient) => (
                          <Chip
                            key={recipient.email}
                            label={recipient.email}
                            sx={{ marginRight: '0.5rem' }}
                            icon={
                              isStandaloneRecepient(recipient) ? (
                                <Tooltip title="Submission via email only">
                                  <MailOutlineRoundedIcon
                                    fontSize="small"
                                    sx={{ cursor: 'pointer' }}
                                  />
                                </Tooltip>
                              ) : undefined
                            }
                          />
                        ))}
                      </Box>
                    </React.Fragment>
                  }
                />
                <RowLayout
                  label={'Sent in CC'}
                  isEmpty={!submission.cc?.length}
                  Component={
                    <React.Fragment>
                      <Box display={'flex'} flexWrap={'wrap'} gap={1}>
                        {submission.cc?.map((recipient, index) => (
                          <Chip key={index} label={recipient} />
                        ))}
                      </Box>
                    </React.Fragment>
                  }
                />
                <RowLayout
                  label={'Submitted by'}
                  isEmpty={!submission.submittedBy}
                  Component={
                    <Typography variant="body1">
                      {submission.submittedBy?.name}
                      {' ∙ '}
                      <Box component={'span'} color={'text.secondary'}>
                        {submissionDate}
                      </Box>
                    </Typography>
                  }
                />
                <RowLayout
                  label={'Submission view only link'}
                  isEmpty={!submission.submissionLink}
                  Component={
                    <Link
                      href={submission.submissionLink ?? '#'}
                      color="text.link"
                      target="_blank"
                      sx={{ cursor: 'pointer' }}
                    >
                      {submission.submissionLink}
                    </Link>
                  }
                />
              </Stack>
              <Stack direction={'row'} gap={1}>
                {SubmissionStatus.Draft === submission.status && (
                  <CustomButton
                    size="small"
                    color="secondary"
                    label={'Edit submission'}
                    onClick={onEditSubmission}
                  />
                )}
                {SubmissionStatus.Draft !== submission.status && (
                  <CustomButton
                    size="small"
                    color="secondary"
                    label="Set scheduled interview date"
                    onClick={() =>
                      setNextSubmissionStatus(SubmissionStatus.CallScheduled)
                    }
                    startIcon={<EventAvailableRoundedIcon fontSize="small" />}
                  />
                )}
                {SubmissionStatus.Draft !== submission.status && (
                  <CustomButton
                    size="small"
                    color="secondary"
                    label={'Mark as rejected'}
                    onClick={() =>
                      setNextSubmissionStatus(SubmissionStatus.Rejected)
                    }
                    startIcon={<PersonOffOutlinedIcon fontSize="small" />}
                  />
                )}
              </Stack>
            </Stack>
            <Stack mx={{ xs: '-0.25rem', sm: 0 }}>
              <Stack
                direction="row"
                flex={1}
                sx={{
                  mx: '0.25rem',
                }}
              >
                <Typography variant="body1" flex={1}>
                  Submitted details
                </Typography>
                <Typography variant="body1" color={'text.secondary'}>
                  Original candidate details
                </Typography>
              </Stack>
              <Stack>
                <RowLayout
                  label={'Country'}
                  isEmpty={!submission.profile?.country}
                  Component={
                    <Typography variant="body1">
                      {submission.profile?.country}
                    </Typography>
                  }
                />
                <RowLayout
                  label={'City'}
                  isEmpty={!submission.profile?.city}
                  Component={
                    <Typography variant="body1">
                      {submission.profile?.city}
                    </Typography>
                  }
                />
                <RowLayout
                  label={'English'}
                  isEmpty={!submission.profile?.englishLevel}
                  Component={
                    <Typography variant="body1">
                      {submission.profile?.englishLevel}
                    </Typography>
                  }
                />
                <RowLayout
                  label={'Specialization'}
                  isEmpty={!submission.profile?.specialization}
                  Component={
                    <Typography variant="body1">
                      {submission.profile?.specialization}
                    </Typography>
                  }
                />
                <RowLayout
                  label={'Skills'}
                  Component={
                    <Stack direction="column">
                      <Stack direction="row" gap={1} flexWrap="wrap">
                        {submission.profile?.primarySkills?.map((skill) => (
                          <Box
                            component={'span'}
                            sx={(theme) => ({
                              background: theme.palette.highlight.neutral,
                              padding: '0.25rem 0.5rem',
                              borderRadius: '4px',
                              color: 'text.primar',
                              fontSize: '0.813rem',
                              textWrap: 'nowrap',
                            })}
                            key={skill}
                          >
                            {skill}
                          </Box>
                        ))}
                      </Stack>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ ml: '0.5rem', mt: '0.25rem' }}
                      >
                        {submission.profile?.secondarySkills?.join(', ') || '—'}
                      </Typography>
                    </Stack>
                  }
                />
                <RowLayout
                  label={'Test task'}
                  isEmpty={!submission.testTasks?.[0]?.technology}
                  Component={
                    <Typography variant="body1">
                      Status:{' '}
                      <Box
                        component={'span'}
                        color={
                          TestTaskColor[
                            submission.testTasks?.[0]?.status as TestTaskStatus
                          ] ?? '#000'
                        }
                      >
                        {submission.testTasks?.[0]?.status || '—'}
                      </Box>
                      {' ∙ '}
                      Technology: {submission.testTasks?.[0]?.technology || '—'}
                      {' ∙ '}
                      {!isNil(submission.testTasks?.[0]?.score) && (
                        <React.Fragment>
                          Score:{' '}
                          <Box
                            component={'span'}
                            color={
                              TestTaskColor[
                                submission.testTasks?.[0]
                                  ?.status as TestTaskStatus
                              ] ?? '#000'
                            }
                          >
                            {submission.testTasks?.[0]?.score}%
                          </Box>
                        </React.Fragment>
                      )}
                    </Typography>
                  }
                />
                <RowLayout
                  label={'Experience'}
                  isEmpty={!isNumber(submission.profile?.experience)}
                  Component={
                    <Typography variant="body1">
                      {submission.profile?.experience} years
                    </Typography>
                  }
                />
                <RowLayout
                  label={'Seniority'}
                  isEmpty={!submission.profile?.seniority}
                  Component={
                    <Typography variant="body1">
                      {submission.profile?.seniority}
                    </Typography>
                  }
                />
                <RowLayout
                  label={'Notice period'}
                  isEmpty={!submission.profile?.noticePeriod}
                  Component={
                    <Typography variant="body1">
                      {submission.profile?.noticePeriod}
                    </Typography>
                  }
                />
                <RowLayout
                  label={'Resume'}
                  isEmpty={!submission.profile?.resumeName}
                  Component={
                    <Link
                      href={apiEndpoints.resumeFileUrl(
                        submission.profile?.resumeName || '',
                      )}
                      color="text.link"
                      target="_blank"
                      sx={{ cursor: 'pointer' }}
                      variant="body1"
                    >
                      {submission.profile?.resumeName}
                    </Link>
                  }
                />
              </Stack>
            </Stack>
            <ClientFeeback submission={submission} />
          </Stack>
        </AccordionDetails>
      </Accordion>
      <SubmissionStatusChangeModal
        submission={submission}
        nextSubmissionStatus={nextSubmissionStatus}
        onClose={() => setNextSubmissionStatus(null)}
      />
    </React.Fragment>
  );
};
