import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikErrors } from 'formik';
import { isEmpty, omit } from 'lodash';

import {
  AppBar,
  Box,
  Button,
  Hidden,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import {
  SubmissionStep,
  SubmissionStepsOrdered,
  AppRoutes,
  CandidateDetailsTabs,
} from '@constants';
import { getStepOrder } from '@utils';
import { IZohoCandidate, SubmissionFormValues } from '@types';
import { SubmissionCandidateNameHeader } from './SubmissionCandidateNameHeader';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.brand.accent,
  color: theme.palette.text.invertedPrimary,
  padding: '0.625rem',
  borderRadius: '0.5rem',
  '&:hover': {
    backgroundColor: alpha(theme.palette.brand.accent, 0.7),
  },
}));

const NavbarButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
  },
}));

export const SubmisionFormHeader: React.FC<{
  candidateDetails: IZohoCandidate | null;
  activeStep: SubmissionStep;
  errors: FormikErrors<SubmissionFormValues>;
  onSelectStep: (selectedStep: SubmissionStep) => void;
  onSaveDraftClick: () => void;
}> = ({
  candidateDetails,
  activeStep,
  errors,
  onSelectStep,
  onSaveDraftClick,
}) => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    const navigateTo = candidateDetails
      ? `/${AppRoutes.CANDIDATES}/${candidateDetails.id}/${CandidateDetailsTabs.SUBMISSIONS}`
      : `/${AppRoutes.CANDIDATES}`;

    navigate(navigateTo);
  };

  return (
    <React.Fragment>
      <AppBar
        position="static"
        sx={(theme) => ({
          boxShadow: 'none',
          backgroundColor: theme.palette.background.backgroundPrimary,
        })}
      >
        <Toolbar
          sx={{
            padding: {
              xs: '0.75rem 1rem',
            },
            justifyContent: 'space-between',
            gap: '0.35rem',
          }}
        >
          <Stack
            gap="0.5rem"
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <StyledIconButton
              size="large"
              aria-label="go back"
              onClick={handleBackButtonClick}
            >
              <ArrowBackRoundedIcon />
            </StyledIconButton>
            <Hidden mdDown>
              <SubmissionCandidateNameHeader
                candidateDetails={candidateDetails}
              />
            </Hidden>
          </Stack>
          <Box>
            <Stepper
              activeStep={SubmissionStepsOrdered.findIndex(
                (e) => e === activeStep,
              )}
              sx={{
                '& .MuiStepLabel-root .Mui-completed': {
                  color: '#3F8CFF !important',
                },
                '& .MuiStepLabel-root .Mui-active': {
                  color: '#4f4f4d !important',
                },
                '& .MuiStepLabel-root .Mui-error': {
                  color: '#c62828 !important',
                },
                '& .MuiStepConnector-root': {
                  display: 'none !important',
                },
              }}
            >
              {SubmissionStepsOrdered.map((label) => {
                const hasProfileError =
                  label === SubmissionStep.Profile &&
                  label !== activeStep &&
                  !isEmpty(omit(errors.profile, 'resumeName'));
                const hasTTError =
                  label === SubmissionStep.TestTask &&
                  label !== activeStep &&
                  !isEmpty(errors.testTask);
                const hasFeedbackError =
                  label === SubmissionStep.Feedback &&
                  label !== activeStep &&
                  !isEmpty(errors.feedback);
                const hasInterviewError =
                  label === SubmissionStep.Interview &&
                  label !== activeStep &&
                  !isEmpty(errors.interview);
                const hasRecipientsError =
                  label === SubmissionStep.Recipients &&
                  label !== activeStep &&
                  (!isEmpty(errors.clientName) || !isEmpty(errors.positions));

                const stepOrder = getStepOrder(label);

                return (
                  <Step
                    key={label}
                    sx={{
                      padding: {
                        xs: '2px',
                        sm: '4px',
                        lg: '8px',
                      },
                    }}
                  >
                    <StepLabel
                      error={
                        hasProfileError ||
                        hasTTError ||
                        hasFeedbackError ||
                        hasInterviewError ||
                        hasRecipientsError
                      }
                      onClick={() => onSelectStep(label)}
                      sx={{
                        cursor: 'pointer !important',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: { xs: 'none', sm: 'flex' },
                        color: '#4f4f4d',
                      }}
                      StepIconProps={{
                        classes: { text: 'MuiStepIcon-text' },
                      }}
                      StepIconComponent={({ active, completed, error }) => (
                        <Box
                          sx={{
                            width: 24,
                            height: 24,
                            borderRadius: '50%',
                            bgcolor: error
                              ? '#c62828'
                              : completed
                              ? '#3F8CFF'
                              : active
                              ? '#4f4f4d'
                              : 'lightgrey',
                            display: { xs: 'none', md: 'none', lg: 'flex' },
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            fontWeight: 300,
                          }}
                        >
                          {stepOrder}
                        </Box>
                      )}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>

          <NavbarButtonsContainer>
            <Button
              variant="text"
              sx={{
                justifyContent: 'end',
                color: 'text.link',
                fontWeight: 500,
                textTransform: 'unset',
                fontSize: '0.8125rem',
                wordWrap: 'no-wrap',
                whiteSpace: 'nowrap',
                ml: -2,
                padding: '5px',
                display: {
                  xs: 'block',
                  sm: 'none',
                },
              }}
              onClick={onSaveDraftClick}
            >
              Save as draft
            </Button>
            <Button
              variant="contained"
              onClick={handleBackButtonClick}
              sx={{
                backgroundColor: '#3F8CFF14',
                color: '#42A5F5',
                textTransform: 'none',
                display: 'flex',
                paddingRight: 2,
              }}
            >
              Cancel
            </Button>
          </NavbarButtonsContainer>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};
