import { useEffect, useState } from 'react';
import { isAuthStatusLoading } from '../utils';
import { authSelectors } from '@redux/auth';
import { useAppSelector } from '@redux/hooks';

export const useAppIsLoading = () => {
  const authStatus = useAppSelector(authSelectors.getAuthStatus);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(isAuthStatusLoading(authStatus));
  }, [authStatus]);

  return isLoading;
};
