import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { Box, Typography } from '@mui/material';
import { isStatusFailed, isStatusForbidden } from '@utils';

import {
  clearJobOpeningDetails,
  fetchJobOpeningById,
  fetchJobOpeningCandidates,
  jobOpeningDetailsSelectors,
} from '@redux/jobOpeningDetails';

import {
  ForbiddenView,
  JobOpeningCandidates,
  JobOpeningInfo,
  Flex,
} from '@components';

export const JobOpeningDetails: React.FC = () => {
  const dispatch = useAppDispatch();

  const joAPIStatus = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningDetailsApiStatus,
  );
  const id = useAppSelector(jobOpeningDetailsSelectors.getJobOpeningDetailsId);

  useEffect(() => {
    const fetchDetails = async () => {
      if (id) {
        await dispatch(fetchJobOpeningById(id));
        await dispatch(fetchJobOpeningCandidates(id));
      }
    };

    fetchDetails();

    return () => {
      if (id) {
        dispatch(clearJobOpeningDetails());
      }
    };
  }, [id]);

  if (isStatusFailed(joAPIStatus)) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Typography variant="h3">Job opening not found</Typography>
      </Box>
    );
  }

  if (isStatusForbidden(joAPIStatus)) {
    return <ForbiddenView />;
  }

  return (
    <Flex
      gap={5}
      alignItems="start"
      flexWrap="wrap"
      sx={{
        padding: {
          xs: '8px',
          sm: '12px',
          md: '16px',
          lg: '20px 40px',
        },
      }}
    >
      <Flex
        flex={3}
        gap={1}
        flexDirection="column"
        alignItems="start"
        maxWidth="550px"
      >
        <Typography color="text.primary" variant="h4">
          Position details
        </Typography>
        <Box
          sx={(theme) => ({
            boxShadow: 'none',
            backgroundColor: theme.palette.background.backgroundPrimary,
            padding: 1,
            borderRadius: '8px',
          })}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.background.paper,
              padding: '8px 24px 32px 24px',
            })}
          >
            <JobOpeningInfo />
          </Box>
        </Box>
      </Flex>

      <Box flex={3}>
        <JobOpeningCandidates />
      </Box>
    </Flex>
  );
};
