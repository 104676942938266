import { useEffect } from 'react';

import { useAppDispatch } from '@redux/hooks';
import { fetchActionReport } from '@redux/action-report';

export const useFetchActionReport = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchActionReport());
  }, []);
};
